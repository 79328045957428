import {
  AvailableLanguages,
  MixpanelClient,
  MixpanelEvent,
  onboardingClient,
} from '@enaratech/funnel-helper';
import { Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import RadioButtons from 'src/components/Common/Inputs/RadioButtons/RadioButtons';
import Modal from 'src/components/Common/Modal/Modal';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_FIRST_LANGUAGE } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';

type Props = {
  onSelect: (language: AvailableLanguages) => void;
  isOpen: boolean;
  noAvailableAppointments?: boolean;
  value: AvailableLanguages | null;
};

export const getLanguageName = (language: AvailableLanguages) => {
  switch (language) {
    case AvailableLanguages.Arabic:
      return 'Arabic';
    case AvailableLanguages.Spanish:
      return 'Spanish';
    default:
      return 'English';
  }
};

const LanguageSelector: FC<Props> = ({ value, isOpen, noAvailableAppointments, onSelect }) => {
  const pathname = useRoutePath();
  const [language, setLanguage] = useState<AvailableLanguages>(value ?? AvailableLanguages.English);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(isOpen);
  const [isFirstMount, setIsFirstMount] = useState<boolean>(true);
  const { dispatchOnboarding } = useOnboarding();

  const updateUserLanguage = (language: AvailableLanguages) => {
    onboardingClient.updateMemberStatus({ firstLanguage: language! });

    dispatchOnboarding({
      type: SET_FIRST_LANGUAGE,
      payload: language!,
    });
  };

  const handleClose = () => {
    MixpanelClient.trackEvent({
      event: MixpanelEvent.Click,
      properties: {
        field: 'Select Language',
        value: language,
        source: pathname,
      },
    });
    onSelect(language);
    updateUserLanguage(language);
    setModalIsOpen(false);
  };

  useEffect(() => {
    setIsFirstMount(false);
  }, []);

  useEffect(() => {
    setModalIsOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen || isFirstMount}
        title={
          <Typography variant='h3' mb={2}>
            {noAvailableAppointments
              ? `There are not available providers for this language. Please try selecting another one.`
              : 'Please choose your preferred language for your first appointments.'}
          </Typography>
        }
        subtitle='You can change your selection later.'
        onClose={handleClose}>
        <RadioButtons
          name='Language'
          value={language}
          onChange={(e) => setLanguage(e.target.value as AvailableLanguages)}
          trackingDisabled
          options={[
            {
              label: getLanguageName(AvailableLanguages.English),
              value: AvailableLanguages.English,
            },
            {
              label: getLanguageName(AvailableLanguages.Arabic),
              value: AvailableLanguages.Arabic,
            },
            {
              label: getLanguageName(AvailableLanguages.Spanish),
              value: AvailableLanguages.Spanish,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default LanguageSelector;
