import {
  AcuityAppointmentTag,
  getLocalTime,
  getNextAvailableWeekdayForScheduling,
  getPartnerById,
  isHPSMInsurance,
  MixpanelClient,
  MixpanelEvent,
  Partner,
  scheduleClient,
} from '@enaratech/funnel-helper';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Modal from 'src/components/Common/Modal/Modal';
import { Page, withProgress } from 'src/components/Common/Progress/IPO/IPOProgress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { useClinic } from 'src/contexts/clinic/index';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { IPOPath, navigateToPage } from 'src/pages/routes';
import AppointmentCalendar from '../../../Appointments/Scheduler/AppointmentsScheduler/AppointmentsScheduler';
import {
  APPOINTMENT_TYPE_ID_QUERY_PARAM,
  calendarIDsByClinicForIPO,
  getSchedulingPlatformEnvironment,
} from './config';

const IPOEligibilityCheckBooking: FC = () => {
  const [appointmentDateTime, setAppointmentDateTime] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isAppointmentCreatedModalOpen, setIsAppointmentCreatedModalOpen] =
    useState<boolean>(false);

  const [clinic, setClinic] = useState<Partner | null>(null);

  const navigate = useNavigate();

  const { clinicState } = useClinic();

  const {
    authState: { user },
  } = useAuth();

  const routePath = useRoutePath();
  const [searchParams] = useSearchParams();
  const appointmentTypeId = Number.parseInt(`${searchParams.get(APPOINTMENT_TYPE_ID_QUERY_PARAM)}`);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (!appointmentTypeId || !clinic) {
      Toast.notification('error', 'There is no appointment type selected');
      return setIsSubmitting(false);
    }

    const calendarId =
      calendarIDsByClinicForIPO[getSchedulingPlatformEnvironment()][clinic.urlSlug]!;

    const appointmentCreated = await scheduleClient.createAppointment({
      appointmentTypeId,
      calendarId,
      dateTime: appointmentDateTime!,
      tag: AcuityAppointmentTag.FunnelAppIPO,
    });

    if (appointmentCreated) {
      MixpanelClient.trackEvent({
        event: MixpanelEvent.InputData,
        properties: {
          field: 'Appointment created',
          value: 'Eligibility Check',
          source: routePath,
        },
      });

      setIsAppointmentCreatedModalOpen(true);
    } else {
      Toast.notification('error', 'Appointment was not created');
    }

    setIsSubmitting(false);
  };

  const fetchAvailability = async (
    currentDay: DateTime
  ): Promise<
    | {
        acuityAppointmentTypeId: number;
        acuityCalendarIds: number[];
        localDateTime: string;
        dateTime: string;
      }[]
    | null
  > => {
    if (!appointmentTypeId || !clinic) {
      Toast.notification('error', 'There is no appointment type selected');
      return null;
    }

    const calendarId =
      calendarIDsByClinicForIPO[getSchedulingPlatformEnvironment()][clinic.urlSlug]!;

    const appointments = await scheduleClient.getAppointmentTimesByCalendarIdAndAppointmentTypeId({
      appointmentTypeId: `${appointmentTypeId}`,
      calendarId: `${calendarId}`,
      datetime: currentDay.toFormat('yyyy-MM-dd'),
    });

    if (appointments) {
      return appointments.map(({ time }) => {
        const localTime = getLocalTime({
          isoDateToConvert: time,
          localTimezone: DateTime.local().zoneName,
        });

        return {
          acuityAppointmentTypeId: appointmentTypeId,
          acuityCalendarIds: [calendarId],
          localDateTime: localTime,
          dateTime: time,
        };
      });
    }

    return null;
  };

  useEffect(() => {
    const clinic = getPartnerById(clinicState!.details.clinicId, clinicState!.partners)!;
    setClinic(clinic);
  }, [clinicState]);

  return (
    <BasicLayout
      title={`${user?.firstName}, will further check for eligibility status.`}
      subtitle={
        <Typography variant={'h4'}>
          Please set up a call in 3 days time in order to check insurance eligibility
        </Typography>
      }
      buttonProps={{
        text: 'Confirm Appointment',
        disabled: !appointmentDateTime || isHPSMInsurance(user!.insuranceCompany),
        loading: isSubmitting,
        onClick: handleSubmit,
      }}>
      <Modal
        isOpen={isAppointmentCreatedModalOpen}
        title={
          <Typography variant='h3'>Thanks! The appointment was created successfully</Typography>
        }
        subtitle=''
        onClose={() => {
          navigateToPage({ targetPage: IPOPath.Welcome, navigate });
        }}></Modal>
      <AppointmentCalendar
        initialDaysToSkip={getNextAvailableWeekdayForScheduling()}
        onNoAvailabilityFound={() => {}}
        onSelectHour={(selectedAvailability: string | null) => {
          setAppointmentDateTime(selectedAvailability);
        }}
        getCurrentAvailability={fetchAvailability}
      />
    </BasicLayout>
  );
};

export default withProgress(IPOEligibilityCheckBooking, Page.IPOEligibilityCheckBooking);
