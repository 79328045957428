import {
  CoverageInfo,
  crmClient,
  FeatureManager,
  getHintPlanIdForMembershipType,
  getParsedCoverageInfo,
  hintClient,
  isTechMembership,
  LeadOnboardingStage,
  MembershipType,
  MixpanelClient,
  onboardingClient,
  PaymentOption,
  StatsigEventName,
  StatsigManager,
  STRIPE_PUBLIC_KEY,
  userClient,
} from '@enaratech/funnel-helper';
import { Grid, Stack, Typography } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_HINT_PATIENT_ID } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useExperiment } from 'src/contexts/experiments';
import { useOnboarding } from 'src/contexts/onboarding';
import { navigateToPage } from '../routes';
import CoverageResult from './CoverageResult';
import { inputFields, PLAN_OPTIONS } from './Inputs/inputFields';
import {
  ANNUAL_PLAN_DURATION,
  MONTHLY_PLAN_DURATION,
  PaymentForm,
  PaymentMethodResult,
  ReturnData,
} from './Inputs/Payment.types';
import PaymentInputs from './Inputs/PaymentInputs';
import './scss/payments.scss';

const Payment: FC = () => {
  const [canContinue, setCanContinue] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState<string>('');
  const [plan, setPlan] = useState<number>(MONTHLY_PLAN_DURATION);
  const [stripePromise] = useState(() => loadStripe(STRIPE_PUBLIC_KEY));
  const [stripePaymentMethod, setStripePaymentMethod] = useState<PaymentMethodResult | undefined>(
    undefined
  );
  const [discount, setDiscount] = useState<ReturnData>({
    discountMonthly: 0,
    discountAnnually: 0,
    coupon: 0,
    duration: 0,
  });
  const [coverage, setCoverage] = useState<CoverageInfo | null>(null);
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);

  const agreementsRef = useRef<RefAgreements>(null);
  const navigate = useNavigate();

  const {
    authState: { user, isReferral },
    dispatchAuth,
  } = useAuth();

  const {
    onboardingState: {
      eligibility: { membershipType },
      magicLink,
      locationFlow,
    },
  } = useOnboarding();

  const { clinicState } = useClinic();

  // Experiment facing changes
  const {
    experimentState: { isInitialized },
  } = useExperiment();

  const isArrivingFromAdwords = useMemo(() => {
    if (!isInitialized) {
      return false;
    }

    return StatsigManager.isArrivingFromAdwords();
  }, [isInitialized]);

  const [formState, setFormState] = useState<PaymentForm>({
    street: '',
    city: clinicState!.patient.city ?? '',
    state: clinicState!.patient.state ?? '',
    zipCode: user!.zipCode ?? '',
  });

  const hint = clinicState?.hint;

  const options = {
    fonts: [
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Proxima+Nova',
      },
    ],
  };

  const getPaymentMethod = (paymentMethod: PaymentMethodResult) => {
    setStripePaymentMethod(paymentMethod);
  };

  const getInitialPrice = () => {
    if (!coverage) {
      return '';
    }

    return plan === MONTHLY_PLAN_DURATION
      ? `${coverage.prices.monthly}`
      : `${coverage.prices.annual}`;
  };

  const getDiscountPrice = () => {
    return plan === MONTHLY_PLAN_DURATION
      ? `${discount.discountMonthly}`
      : `${discount.discountAnnually}`;
  };

  const shouldGoToSSBFlow = async (): Promise<boolean> => {
    if (
      (locationFlow && FeatureManager.shouldForceGoToSSBForReferralHubFlow(locationFlow)) ||
      magicLink ||
      FeatureManager.shouldReferralGoThroughSSB({ isReferral, clinicId: user!.clinicId })
    ) {
      return true;
    }

    return await FeatureManager.isSelfServeBookingFeatureEnabled(
      clinicState!.details.clinicId,
      user!.insuranceCompany
    );
  };

  const handleSubmit = async () => {
    const { firstName, lastName, email } = user!;

    const IDs: {
      patient: string | null;
      membership: string | null;
      card: string | null;
      techPatient: string | null;
      techMembership: string | null;
      techCard: string | null;
    } = {
      patient: null,
      membership: null,
      card: null,
      techPatient: null,
      techMembership: null,
      techCard: null,
    };

    if (stripePaymentMethod?.error) {
      return;
    }

    setIsSubmitting(true);

    const isTech = isTechMembership(membershipType);

    try {
      const patient = await hintClient.createPatient({
        email,
        first_name: firstName,
        last_name: lastName,
        lead_source_id: hint!.leadSource,
        location_id: hint!.location,
        practitioner_id: hint!.supervisingMd,
        hint_code: hint!.hintCode,
      });

      IDs.patient = patient.id;

      if (!isTech) {
        const card = await hintClient.createPaymentMethod({
          patient_id: patient.id,
          stripe_id: stripePaymentMethod!.stripePaymentMethod.id,
          hint_code: hint!.hintCode,
        });

        IDs.card = card?.id ?? null;
      }

      const membership = await hintClient.createMembership({
        patient_id: patient.id,
        plan_id: getHintPlanIdForMembershipType({
          clinic: clinicState!,
          membershipType: membershipType!,
          insuranceOutcome: user!.insuranceOutcome,
        }),
        period_in_months: plan,
        hint_code: hint!.hintCode,
      });

      IDs.membership = membership?.id ?? null;

      if (isTech) {
        const techPatient = await hintClient.createPatient({
          email,
          first_name: firstName,
          last_name: lastName,
          lead_source_id: hint!.otherSubscription!.leadSource,
          location_id: hint!.otherSubscription!.location,
          practitioner_id: hint!.otherSubscription!.supervisingMd,
          hint_code: hint!.otherSubscription!.hintCode,
        });

        IDs.techPatient = techPatient.id;

        const [techCard, techMembership] = await Promise.all([
          hintClient.createPaymentMethod({
            patient_id: techPatient.id,
            stripe_id: stripePaymentMethod!.stripePaymentMethod.id,
            hint_code: hint!.otherSubscription!.hintCode,
          }),
          hintClient.createMembership({
            patient_id: techPatient.id,
            plan_id: getHintPlanIdForMembershipType({
              clinic: clinicState!,
              membershipType: membershipType!,
              isAppSubscription: true,
              insuranceOutcome: user!.insuranceOutcome,
            }),
            period_in_months: plan,
            hint_code: hint!.otherSubscription!.hintCode,
          }),
        ]);

        IDs.techCard = techCard?.id ?? null;
        IDs.techMembership = techMembership?.id ?? null;
      }

      const paymentOption: PaymentOption = plan === ANNUAL_PLAN_DURATION ? 'yearly' : 'monthly';

      crmClient.updateLead({
        uuid: user!.uuid,
        shownPrice: getInitialPrice(),
        finalPrice: discount.coupon !== 0 ? getDiscountPrice() : getInitialPrice(),
        billingFrequency: paymentOption,
        couponCode: promoCode,
      });

      userClient.updateUser({
        address: formState.street,
        city: formState.city,
        state: formState.state,
        zipCode: formState.zipCode,
        hintId: IDs.patient,
      });

      dispatchAuth({ type: SET_HINT_PATIENT_ID, payload: IDs.patient });

      if (agreementsRef.current) {
        agreementsRef.current.signAgreements();
      }

      MixpanelClient.setMetadata({ paymentOption });

      onboardingClient.updateMemberStatus({
        onboardingStage: LeadOnboardingStage.Payment,
      });

      if (await shouldGoToSSBFlow()) {
        navigateToPage({ targetPage: '/appointment/self-serve-booking', navigate });
      } else if (isArrivingFromAdwords) {
        // Members coming from an Adwords campaign should be redirected to the congrats
        // screen instead Member Experience Booking since they already scheduled an
        // appointment for Question Talk
        StatsigManager.logEvent({
          eventName: StatsigEventName.CongratulationScreenDisplayed,
          metadata: {
            memberId: `${user!.id}`,
            memberUuid: user!.uuid,
          },
        });
        return navigateToPage({ targetPage: '/onboarding-call-result', navigate });
      } else if (user!.initialAppointmentId) {
        // Members coming from the legacy funnel might have already booked with MS
        navigateToPage({ targetPage: '/appointment/ms-booking/result', navigate });
      } else {
        navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
      }
    } catch (error) {
      Toast.notification('error', (error as any).message);

      await hintClient.rollbackHintSetup({
        common: {
          hintCode: hint!.hintCode,
          patientId: IDs.patient,
          membershipId: IDs.membership,
          cardId: IDs.card,
        },
        appSubscription: {
          hintCode: hint?.otherSubscription?.hintCode ?? null,
          patientId: IDs.techPatient,
          membershipId: IDs.techMembership,
          cardId: IDs.techCard,
        },
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleGetCoverage = async () => {
    const coverageResponse = await getParsedCoverageInfo({
      membershipType: membershipType ?? MembershipType.Private,
      insuranceCompany: user!.insuranceCompany,
      programType: user!.programType,
      clinicId: user!.clinicId,
    });

    setCoverage(coverageResponse);
  };

  useEffect(() => {
    handleGetCoverage();
  }, []);

  return (
    <BasicLayout
      title={'Your personalized journey is about to get started!'}
      loading={!coverage}
      buttonProps={{
        text: 'Book my First Appointment',
        disabled:
          !canContinue ||
          !agreementsLoaded ||
          !allAgreementsSelected ||
          Object.values(formState).every((i) => i!.length === 0),
        loading: isSubmitting,
        onClick: handleSubmit,
      }}
      className='container-payment'
      back>
      <Grid container justifyContent={'space-around'} direction={'row-reverse'}>
        <Grid item md={12} lg={5} xl={4}>
          {coverage && (
            <CoverageResult
              addPlan={setPlan}
              coverage={coverage}
              planOptions={PLAN_OPTIONS}
              discount={discount}
              setDiscount={setDiscount}
              setPromo={setPromoCode}
            />
          )}
        </Grid>
        <Grid item md={12} lg={6} xl={7}>
          <Stack>
            <Typography variant={'h4'}>Payment Method</Typography>
            <Typography variant={'body2'} style={{ lineHeight: '28px' }}>
              You will only be charged after your first appointment with your care team.
            </Typography>
            <Elements stripe={stripePromise} options={options}>
              <PaymentInputs
                patient={`${user?.firstName} ${user?.lastName}`}
                inputs={inputFields}
                setCanContinue={setCanContinue}
                form={formState}
                setForm={setFormState}
                getPaymentMethod={getPaymentMethod}
              />
            </Elements>

            <Agreements
              ref={agreementsRef}
              agreementsMode={'needs-auth'}
              address={formState.street}
              onLoaded={useCallback(() => setAgreementsLoaded(true), [])}
              onSelect={useCallback((all: boolean) => setAllAgreementsSelected(all), [])}
            />
          </Stack>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default withProgress(Payment, Page.Payment);
