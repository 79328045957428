import {
  AVAILABLE_PLAN_TYPES,
  AvailableLanguages,
  ContactMethod,
  CreateUserPayload,
  ElementTracker,
  ElementTrackerType,
  EligibilitySource,
  FUNNEL_VERSION,
  InsuranceCardFace,
  MembershipType,
  Metadata,
  MixpanelClient,
  MixpanelEvent,
  Payer,
  PlanType,
  StatsigEventName,
  StatsigManager,
  coreClient,
  eligibilityClient,
  isEmailValid,
  isHPSMInsurance,
  isHeightFeetValid,
  isHeightInchesValid,
  isPhoneValid,
  isTestingCredential,
  isValidDateOfBirth,
  isWeightLbsValid,
  onboardingClient,
  slugify,
  storeAuth,
  useDebounce,
  useViewport,
  userClient,
} from '@enaratech/funnel-helper';
import { Autocomplete, Box, Checkbox, Stack, TextField, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  ChangeEvent,
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import DatePicker from 'src/components/Common/Inputs/DatePicker/DatePicker';
import RadioButtons from 'src/components/Common/Inputs/RadioButtons/RadioButtons';
import Modal from 'src/components/Common/Modal/Modal';
import { Page, withProgress } from 'src/components/Common/Progress/IPO/IPOProgress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useExperiment } from 'src/contexts/experiments';
import { SET_USER_METADATA } from 'src/contexts/experiments/types';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { NewMemberProfileStorageForm, ProgressKey, getProgress } from 'src/lib/IPOStorage';
import { expandExperimentUser } from 'src/lib/experiments';
import { GENDER_OPTIONS } from '../../DOB/DateOfBirth';
import FileUpload from '../../Insurance/FileUpload/FileUpload';
import { IPOPath, navigateToPage } from '../../routes';
import {
  checkEligibilityAppointmentTypeByLanguageForIPO,
  getSchedulingPlatformEnvironment,
} from '../Appointments/EligibilityCheck/config';
import { CLINICS } from './Clinics';
import { InputFields, MANDATORY_FIELDS } from './config';
import './scss/newMemberProfile.scss';

const PREFERRED_LANGUAGE_OPTIONS = Object.keys(AvailableLanguages).map((e) => ({
  id: AvailableLanguages[e as keyof typeof AvailableLanguages],
  label: e,
}));

export type NewMemberProfileForm = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  dateOfBirth: DateTime | null;
  gender: string | null;
  preferredLanguage: AvailableLanguages | null;
  heightFeet: string;
  heightInches: string;
  weightLbs: string;
  insuranceType: string | null;
  insuranceCompany: string | null;
  insuranceId: string;
  groupNumber: string;
  referringProvider: string | null;
};

const NewMemberProfile: FC = () => {
  const [formState, setFormState] = useState<NewMemberProfileForm>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dateOfBirth: null,
    gender: null,
    preferredLanguage: null,
    heightFeet: '',
    heightInches: '',
    weightLbs: '',
    insuranceType: null,
    insuranceCompany: null,
    insuranceId: '',
    groupNumber: '',
    referringProvider: null,
  });
  const [eligibilityData, setEligibilityData] = useState<{ membershipType: MembershipType | null }>(
    { membershipType: null }
  );
  const [errors, setErrors] = useState<NewMemberProfileStorageForm | {}>({});
  const [frontFaceImage, setFrontFaceImage] = useState<string>();
  const [backFaceImage, setBackFaceImage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isNoEligibilityMatchModalOpen, setIsNoEligibilityMatchModalOpen] =
    useState<boolean>(false);
  const [noEligibilityMatchAppointmentType, setNoEligibilityMatchAppointmentType] =
    useState<number>();
  const [isGroupIdDisabled, setIsGroupIdDisabled] = useState<boolean>(false);
  const [mandatoryFields, setMandatoryFields] =
    useState<{ [key in InputFields]: boolean }>(MANDATORY_FIELDS);
  const [payers, setPayers] = useState<Payer[]>([]);
  const [filteredCarrierNames, setFilteredCarrierNames] = useState<string[]>([]);
  const [carrierNameFilter, setCarrierNameFilter] = useState<string>('');
  const [autocompleteIsLoading, setAutocompleteIsLoading] = useState<boolean>(false);
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);

  const agreementsRef = useRef<RefAgreements>(null);

  const { isMobileView } = useViewport();
  const routePath = useRoutePath();
  const navigate = useNavigate();

  const {
    experimentState: { isInitialized },
    dispatchExperimentState,
  } = useExperiment();
  const { dispatchAuth } = useAuth();
  const { dispatchOnboarding } = useOnboarding();
  const { clinicState } = useClinic();

  const schedulingPlatformEnvironment = getSchedulingPlatformEnvironment();

  const handleBlockGroupIdInput = (): void => {
    setFormState({ ...formState, [InputFields.GroupNumber]: '' });
    setErrors({ ...errors, [InputFields.GroupNumber]: '' });
    setMandatoryFields({ ...mandatoryFields, [InputFields.GroupNumber]: isGroupIdDisabled });
    setIsGroupIdDisabled((prev) => !prev);
  };

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
  }, [isInitialized]);

  const INSURANCE_TYPE_OPTIONS = useMemo(() => {
    return AVAILABLE_PLAN_TYPES.map((planType) => {
      if (planType.value === 'none') {
        return { ...planType, value: 'unknown' };
      } else {
        return planType;
      }
    });
  }, []);

  // TODO: This is only a temporary solution that will be removed once the endpoint that returns the referring provider options is ready (OP-308)
  const REFERRING_PROVIDER_OPTIONS = useMemo(() => {
    const clinicDetails = CLINICS.find((clinic) => clinic.id === clinicState?.details.clinicId);
    if (clinicDetails) {
      const options = clinicDetails.referred_by_options.map((provider) => ({
        id: provider,
        label: provider,
      }));
      const firstOption = `New to ${clinicDetails.name}`;
      options.unshift({ id: firstOption, label: firstOption });
      return options;
    } else return [];
  }, [clinicState?.details.clinicId]);

  const genderDefaultValue = useMemo(() => {
    if (!formState.gender) {
      return null;
    }
    return GENDER_OPTIONS.find((option) => option.value === formState.gender) ?? null;
  }, [formState.gender]);

  const preferredLanguageDefaultValue = useMemo(() => {
    if (!formState.preferredLanguage) return null;
    return (
      PREFERRED_LANGUAGE_OPTIONS.find((option) => option.id === formState.preferredLanguage) ?? null
    );
  }, [formState.preferredLanguage]);

  const insuranceTypeDefaultValue = useMemo(() => {
    if (!formState.insuranceType) return null;
    return (
      INSURANCE_TYPE_OPTIONS.find((option) => option.value === formState.insuranceType) ?? null
    );
  }, [formState.insuranceType]);

  const referringProviderDefaultValue = useMemo(() => {
    if (!formState.referringProvider) return null;
    return (
      REFERRING_PROVIDER_OPTIONS.find((option) => option.id === formState.referringProvider) ?? null
    );
  }, [REFERRING_PROVIDER_OPTIONS, formState.referringProvider]);

  const noEligibilityMatchAppointmentTypeOptions = useMemo(() => {
    // For now, we only have to display the On-Site option
    return [
      {
        label: 'On-site',
        value:
          checkEligibilityAppointmentTypeByLanguageForIPO[schedulingPlatformEnvironment][
            formState.preferredLanguage!
          ],
      },
    ];
  }, [formState.preferredLanguage]);

  const handleFrontFaceSelection = (base64: string) => {
    setFrontFaceImage(base64);
  };

  const handleBackFaceSelection = (base64: string) => {
    setBackFaceImage(base64);
  };

  const renderFileUploader = (face: 'front' | 'back'): ReactElement => (
    <FileUpload
      face={face}
      text={`${
        isMobileView ? 'Tap' : 'Click'
      } to take a picture of the ${face} of your Insurance Card`}
      onSelectFile={face === 'front' ? handleFrontFaceSelection : handleBackFaceSelection}
      previewTitle={face.toUpperCase()}
    />
  );

  const checkOnMandatoryFields = (
    field: InputFields,
    value: string | DateTime | undefined | null
  ) => {
    let errorMessage = '';

    if (!value && mandatoryFields[field]) {
      errorMessage = 'This field is required';
    }

    setErrors({ ...errors, [field]: errorMessage });

    return errorMessage;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const field = e.target.id as InputFields;
    const value = e.target.value;

    let errorMessage = checkOnMandatoryFields(field, value);

    if (!errorMessage) {
      if (value && field === InputFields.Email) {
        errorMessage = !isEmailValid(value) ? 'Email is not valid' : '';
      } else if (value && field === InputFields.Phone && !isTestingCredential(value)) {
        errorMessage = !isPhoneValid(value) ? 'Phone number is not valid' : '';
      } else if (value && field === InputFields.HeightFeet) {
        errorMessage = !isHeightFeetValid(value) ? 'Minimum must be 1 and maximum 10' : '';
      } else if (value && field === InputFields.HeightInches) {
        errorMessage = !isHeightInchesValid(value) ? 'Minimum must be 0 and maximum 11' : '';
      } else if (value && field === InputFields.WeightLbs) {
        errorMessage = !isWeightLbsValid(value)
          ? 'Weight field minimum must be 10 and maximum 999'
          : '';
      }

      setErrors({ ...errors, [field]: errorMessage });
    }

    if (
      [InputFields.HeightFeet, InputFields.HeightInches, InputFields.WeightLbs].includes(
        field as InputFields
      ) &&
      value
    ) {
      setFormState({ ...formState, [field]: value.replace(/\D/g, '') });
    } else {
      setFormState({ ...formState, [field]: value });
    }
  };

  const handleChangeDob = (date: DateTime | null) => {
    let errorMessage = checkOnMandatoryFields(InputFields.DateOfBirth, date);
    if (!errorMessage) {
      if (date) {
        const isDobValid = isValidDateOfBirth(date);

        if (isDobValid === 'invalid') {
          errorMessage = 'Please enter a valid date MM/DD/YYYY';
        } else if (isDobValid === 'below-accepted') {
          errorMessage = 'Oops! We are currently not accepting any members below the age of 16';
        }
      }

      setErrors({ ...errors, dateOfBirth: errorMessage });
    }

    setFormState({ ...formState, dateOfBirth: date });
  };

  const isFormDisabled = () => {
    return (
      Object.entries(formState)
        .filter(([key, _]) => mandatoryFields[key as InputFields])
        .some(([_, value]) => !value) ||
      Object.values(errors).some((i) => i && i.length >= 0) ||
      !agreementsLoaded ||
      !allAgreementsSelected
    );
  };

  const createLead = async () => {
    if (!!formState.email && !(await userClient.checkAvailability(formState.email))) {
      throw new Error('The email address is already in use');
    }
    if (!!formState.phone && !(await userClient.checkAvailability(formState.phone))) {
      throw new Error('The phone number is already in use');
    }

    // ----------------- Create User

    const coreUserParams: CreateUserPayload = {
      carrierName: formState.insuranceCompany ?? undefined,
      clinicId: clinicState?.details.clinicId,
      contactMethod: ContactMethod.FunnelInClinic,
      dateOfBirth: formState.dateOfBirth?.toISODate(),
      email: formState.email ? formState.email : undefined,
      feet: +formState.heightFeet,
      firstName: formState.firstName,
      gender: formState.gender ?? '',
      groupNumber: formState.groupNumber,
      inches: +formState.heightInches,
      insuranceId: formState.insuranceId,
      insuranceType: formState.insuranceType ?? '',
      lastName: formState.lastName,
      phone: formState.phone,
      preferredLanguage: formState.preferredLanguage ?? undefined,
      referringProvider: formState.referringProvider ?? undefined,
      weight: +formState.weightLbs,
    };

    // Fill coreUserParams with properties stored in the progress, if they're available
    const progress = getProgress();
    if (progress) {
      const form = progress[ProgressKey.NewMemberProfile];
      coreUserParams.city = form.city;
      coreUserParams.state = form.state;
      coreUserParams.street = form.street;
      coreUserParams.zipCode = form.zipCode || clinicState?.details.zipCode;
    }
    const userData = await onboardingClient.createLeadAsMember(coreUserParams);

    if (!userData) {
      throw new Error('User could not be created');
    }

    const {
      core: { auth_token: authToken, id, uuid },
    } = userData;

    storeAuth({ id, token: authToken });

    const userMetadata = {
      email: formState.email,
      firstName: formState.firstName,
      lastName: formState.lastName,
      clinicId: clinicState!.details.clinicId,
      programType: clinicState!.program,
      isNewMember: true,
      insuranceCarrier: formState.insuranceCompany ?? undefined,
      insuranceOption: 'manually',
      contactMethod: slugify(ContactMethod.FunnelInClinic),
      referral: 'in-person-onboarding',
    };

    MixpanelClient.identityAlias(id);
    MixpanelClient.setMetadata({
      ...userMetadata,
      experiments: StatsigManager.getExperimentsSummary(),
    } as Partial<Metadata>);

    // Updating user for experiments dashboard
    StatsigManager.logEvent({
      eventName: StatsigEventName.AccountCreation,
      metadata: {
        ...userMetadata,
        memberId: `${id}`,
        uuid,
        isNewMember: 'true',
        insuranceCarrier: `${userMetadata.insuranceCarrier}`,
        clinicId: `${clinicState!.details.clinicId}`,
        systemSource: ContactMethod.FunnelInClinic,
      },
    });

    dispatchExperimentState({
      type: SET_USER_METADATA,
      payload: expandExperimentUser(id, {
        appVersion: FUNNEL_VERSION,
        custom: userMetadata,
        email: userMetadata.email,
        userID: uuid,
      }),
    });

    userClient.createDefaultProviders();
  };

  const comingFromPhoneVerification = useMemo(() => {
    const isFromPhoneVerification = !!getProgress()?.[ProgressKey.PhoneVerification];
    if (isFromPhoneVerification) {
      setMandatoryFields((prev) => {
        return { ...prev, [InputFields.ReferringProvider]: false };
      });
    }
    return isFromPhoneVerification;
  }, []);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      // We have to check if the lead is already registered
      const progress = getProgress();
      if (progress?.[ProgressKey.PhoneVerification]?.leadRegisteredInCore) {
        if (formState.email) {
          try {
            await userClient.updateUser({ email: formState.email });
            MixpanelClient.setMetadata({
              email: formState.email,
            });
          } catch (err) {
            Toast.notification('error', 'User could not be updated');
            return setIsSubmitting(false);
          }
        }
      } else {
        try {
          await createLead();
        } catch (err) {
          Toast.notification('error', (err as Error).message);
          return setIsSubmitting(false);
        }
      }

      if (frontFaceImage) {
        eligibilityClient.uploadInsuranceCardV2({
          face: InsuranceCardFace.Front,
          base64: frontFaceImage,
        });
      }

      if (backFaceImage) {
        eligibilityClient.uploadInsuranceCardV2({
          face: InsuranceCardFace.Back,
          base64: backFaceImage,
        });
      }

      const insuranceCompany = formState.insuranceCompany ?? '';

      // check if Eligibility data returned from LSQ
      let eligibilityResult = { membershipType: eligibilityData.membershipType ?? null };

      const isHPSM = isHPSMInsurance(insuranceCompany);
      const shouldComputeEligibility = !eligibilityResult && !isHPSM;

      if (shouldComputeEligibility) {
        const payer = payers.find((payer) => payer.name === insuranceCompany);
        // If payerId is not defined, eligibility-API is capable to find it by payerName
        eligibilityResult = await eligibilityClient.getManualEligibility({
          groupId: formState.groupNumber,
          payerId: payer?.id,
          payerName: payer?.name || insuranceCompany,
          insuranceMemberId: formState.insuranceId,
          manualPlanType: formState.insuranceType as PlanType,
          source: EligibilitySource.InClinic,
          programType: clinicState!.program,
        });
      }

      if (eligibilityResult?.membershipType) {
        MixpanelClient.setMetadata({ membershipType: eligibilityResult.membershipType, isHPSM });

        // await is needed here since member status has to be saved before calling the fetchAll method (just below)
        await onboardingClient.updateMemberStatus({
          groupId: formState.groupNumber,
          membershipCode: eligibilityResult.membershipType,
        });
      }

      // ----------------- Store data in contexts
      const { user, onboarding, isReferral } = await coreClient.fetchAll();
      if (user) {
        dispatchOnboarding({ type: SET_ONBOARDING_RESULT, payload: onboarding });
        dispatchAuth({ type: SET_AUTH_INFO, payload: { loaded: true, isReferral, user } });
      } else {
        throw new Error("Couldn't load user data");
      }

      if (isHPSM || eligibilityResult) {
        MixpanelClient.setMetadata({ eligibility: true });
        navigateToPage({ targetPage: IPOPath.IPOCoverage, navigate });
      } else {
        MixpanelClient.setMetadata({ eligibility: false });
        // Set default appointment type
        setNoEligibilityMatchAppointmentType(
          checkEligibilityAppointmentTypeByLanguageForIPO[schedulingPlatformEnvironment][
            formState.preferredLanguage!
          ]
        );

        setIsNoEligibilityMatchModalOpen(true);
        return setIsSubmitting(false);
      }

      if (agreementsRef.current) {
        agreementsRef.current.signAgreements();
      }
    } catch (error) {
      Toast.notification('error', 'There was an error, please try again later');
      return setIsSubmitting(false);
    }
  };

  const handleCloseNoEligibilityMatchModal = () => {
    MixpanelClient.trackEvent({
      event: MixpanelEvent.Click,
      properties: {
        field: 'Select Appointment Type',
        value: noEligibilityMatchAppointmentType,
        source: routePath,
      },
    });

    setIsNoEligibilityMatchModalOpen((prev) => !prev);

    navigateToPage({
      targetPage: `/schedule-appointments/eligibility-check?appointmentTypeId=${noEligibilityMatchAppointmentType}`,
      navigate,
    });
  };

  useEffect(() => {
    const progress = getProgress();

    if (progress) {
      const form = progress[ProgressKey.NewMemberProfile];

      setFormState({
        firstName: form?.firstName ?? '',
        lastName: form?.lastName ?? '',
        email: form?.email ?? '',
        phone: form?.phone ?? '',
        dateOfBirth: form?.dateOfBirth ? DateTime.fromISO(form.dateOfBirth).toUTC() : null,
        gender: form?.gender ?? null,
        preferredLanguage: (form?.preferredLanguage as AvailableLanguages) ?? null,
        heightFeet: form?.heightFeet ?? '',
        heightInches: form?.heightInches ?? '',
        weightLbs: form?.weightLbs ?? '',
        insuranceType: form?.insuranceType ?? null,
        insuranceCompany: form?.insuranceCompany ?? null,
        insuranceId: form?.insuranceId ?? '',
        groupNumber: form?.groupNumber ?? '',
        referringProvider: form?.referringProvider ?? null,
      });

      setEligibilityData({ membershipType: form?.membershipType ?? null });
    }
  }, []);

  const fetchCarrierNames = useCallback(
    async ({
      page,
      clinicId,
      filter,
    }: {
      page: number;
      clinicId: number;
      filter?: string;
    }): Promise<void> => {
      const payers = await eligibilityClient.getPayerList({
        page,
        clinicId,
        ...(filter && { filter }),
      });

      setPayers(payers);
      setFilteredCarrierNames(payers.map((payer) => payer.name));
      setAutocompleteIsLoading(false);
    },
    []
  );

  const handleSearchCarrierName = useDebounce((filter: string) => {
    fetchCarrierNames({
      page: 1, // First version uses page 1 (not pagination)
      clinicId: clinicState!.details.clinicId,
      filter,
    });
  });

  useEffect(() => {
    handleSearchCarrierName(carrierNameFilter);
  }, [clinicState, handleSearchCarrierName, carrierNameFilter]);

  useEffect(() => {
    if (clinicState?.details.clinicId) {
      fetchCarrierNames({
        page: 1,
        clinicId: clinicState.details.clinicId,
      });
    }
  }, [clinicState, fetchCarrierNames]);

  return (
    <BasicLayout
      title=''
      buttonProps={{
        disabled: isFormDisabled(),
        text: 'Next',
        loading: isSubmitting,
        onClick: handleSubmit,
      }}
      back>
      <Modal
        isOpen={isNoEligibilityMatchModalOpen}
        title={
          <>
            <Typography variant='h3'>We will further check for eligibility status.</Typography>
            <Typography variant='h3' className='appointment-type-selection-title'>
              Would you like to book a Virtual or On-Site Onboarding Experience?
            </Typography>
          </>
        }
        subtitle='You can change your selection later.'
        onClose={handleCloseNoEligibilityMatchModal}>
        <RadioButtons
          name='No Eligibility Match Appointment Type Selection'
          id='no-eligibility-match-appointment-type-selection'
          onChange={(e, value) => {
            setNoEligibilityMatchAppointmentType(Number.parseInt(value));
          }}
          options={noEligibilityMatchAppointmentTypeOptions}
          value={noEligibilityMatchAppointmentType}
        />
      </Modal>
      <Stack spacing={2}>
        <Box>
          <Typography paragraph variant='h4'>
            New Member Profile
          </Typography>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ElementTracker
              routePath={routePath}
              name='First Name'
              type={ElementTrackerType.Blurrable}>
              <TextField
                id={InputFields.FirstName}
                label={'First Name'}
                variant='filled'
                type={'text'}
                value={formState.firstName || ''}
                className='input'
                onChange={handleChange}
                error={!!errors[InputFields.FirstName as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.FirstName as keyof typeof errors]
                    ? errors[InputFields.FirstName as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
            <ElementTracker
              routePath={routePath}
              name='Last Name'
              type={ElementTrackerType.Blurrable}>
              <TextField
                id={InputFields.LastName}
                label={'Last Name'}
                variant='filled'
                type={'text'}
                value={formState.lastName || ''}
                className='input'
                onChange={handleChange}
                error={!!errors[InputFields.LastName as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.LastName as keyof typeof errors]
                    ? errors[InputFields.LastName as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
          </Stack>
        </Box>
        <Box>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <ElementTracker routePath={routePath} name='Email' type={ElementTrackerType.Blurrable}>
              <TextField
                id='email'
                label={'Email'}
                variant='filled'
                type='email'
                name='email'
                value={formState.email || ''}
                className='input'
                onChange={handleChange}
                error={!!errors[InputFields.Email as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.Email as keyof typeof errors]
                    ? errors[InputFields.Email as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
            <ElementTracker routePath={routePath} name='Phone' type={ElementTrackerType.Blurrable}>
              <TextField
                id='phone'
                label={'Phone'}
                variant='filled'
                type={'text'}
                name='phone'
                value={formState.phone || ''}
                className='input'
                onChange={handleChange}
                error={!!errors[InputFields.Phone as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.Phone as keyof typeof errors]
                    ? errors[InputFields.Phone as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
          </Stack>
        </Box>
        <Box>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <Box>
              <DatePicker
                error={!!errors[InputFields.DateOfBirth as keyof typeof errors]}
                errorMessage={
                  !!errors[InputFields.DateOfBirth as keyof typeof errors]
                    ? errors[InputFields.DateOfBirth as keyof typeof errors]
                    : ''
                }
                onChange={handleChangeDob}
                value={formState.dateOfBirth}
              />
            </Box>
            <Box className='max-content'>
              <Autocomplete
                className='autocomplete autocomplete-custom'
                options={GENDER_OPTIONS}
                isOptionEqualToValue={(option, selectedOption) =>
                  option.value === selectedOption.value
                }
                value={genderDefaultValue}
                renderInput={(params) => (
                  <ElementTracker
                    routePath={routePath}
                    name='Gender'
                    type={ElementTrackerType.Blurrable}>
                    <TextField
                      {...params}
                      placeholder='Gender'
                      className='input'
                      error={!!errors[InputFields.Gender as keyof typeof errors]}
                      helperText={
                        !!errors[InputFields.Gender as keyof typeof errors]
                          ? errors[InputFields.Gender as keyof typeof errors]
                          : ''
                      }
                    />
                  </ElementTracker>
                )}
                onChange={(_, selectedOption) => {
                  checkOnMandatoryFields(InputFields.Gender, selectedOption?.value);
                  setFormState({ ...formState, gender: selectedOption?.value ?? null });
                }}
              />
            </Box>
            <Box className='max-content'>
              <Autocomplete
                className='autocomplete autocomplete-custom'
                options={PREFERRED_LANGUAGE_OPTIONS}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={preferredLanguageDefaultValue}
                renderInput={(params) => (
                  <ElementTracker
                    routePath={routePath}
                    name='Preferred language'
                    type={ElementTrackerType.Blurrable}>
                    <TextField
                      {...params}
                      placeholder='Preferred Language'
                      error={!!errors[InputFields.PreferredLanguage as keyof typeof errors]}
                      helperText={
                        !!errors[InputFields.PreferredLanguage as keyof typeof errors]
                          ? errors[InputFields.PreferredLanguage as keyof typeof errors]
                          : ''
                      }
                    />
                  </ElementTracker>
                )}
                onChange={(_, value) => {
                  checkOnMandatoryFields(InputFields.PreferredLanguage, value?.id);
                  setFormState({ ...formState, preferredLanguage: value?.id ?? null });
                }}
              />
            </Box>
          </Stack>
        </Box>
        <Box>
          <Stack spacing={2}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <ElementTracker routePath={routePath} name='ft.' type={ElementTrackerType.Blurrable}>
                <TextField
                  id={InputFields.HeightFeet}
                  label={'ft.'}
                  variant='filled'
                  inputProps={{ placeholder: 'ft.' }}
                  value={formState.heightFeet || ''}
                  className='input'
                  onChange={handleChange}
                  error={!!errors[InputFields.HeightFeet as keyof typeof errors]}
                  helperText={
                    !!errors[InputFields.HeightFeet as keyof typeof errors]
                      ? errors[InputFields.HeightFeet as keyof typeof errors]
                      : ''
                  }
                />
              </ElementTracker>
              <ElementTracker routePath={routePath} name='in.' type={ElementTrackerType.Blurrable}>
                <TextField
                  id={InputFields.HeightInches}
                  label={'in.'}
                  variant='filled'
                  inputProps={{ placeholder: 'in.' }}
                  value={formState.heightInches || ''}
                  className='input'
                  onChange={handleChange}
                  error={!!errors[InputFields.HeightInches as keyof typeof errors]}
                  helperText={
                    !!errors[InputFields.HeightInches as keyof typeof errors]
                      ? errors[InputFields.HeightInches as keyof typeof errors]
                      : ''
                  }
                />
              </ElementTracker>
            </Stack>
            <ElementTracker routePath={routePath} name='lbs.' type={ElementTrackerType.Blurrable}>
              <TextField
                id={InputFields.WeightLbs}
                label={'lbs.'}
                variant='filled'
                value={formState.weightLbs || ''}
                className='input'
                inputProps={{ placeholder: 'lbs.' }}
                onChange={handleChange}
                error={!!errors[InputFields.WeightLbs as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.WeightLbs as keyof typeof errors]
                    ? errors[InputFields.WeightLbs as keyof typeof errors]
                    : ''
                }
              />
            </ElementTracker>
          </Stack>
        </Box>
        <Box>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <Autocomplete
              className='autocomplete autocomplete-custom'
              options={INSURANCE_TYPE_OPTIONS}
              getOptionLabel={(option) => option.text}
              isOptionEqualToValue={(option, selectedOption) =>
                option.value === selectedOption.value
              }
              value={insuranceTypeDefaultValue}
              renderInput={(params) => (
                <ElementTracker
                  routePath={routePath}
                  name='Insurance Type'
                  type={ElementTrackerType.Blurrable}>
                  <TextField
                    {...params}
                    placeholder='Insurance Type'
                    error={!!errors[InputFields.InsuranceType as keyof typeof errors]}
                    helperText={
                      !!errors[InputFields.InsuranceType as keyof typeof errors]
                        ? errors[InputFields.InsuranceType as keyof typeof errors]
                        : ''
                    }
                  />
                </ElementTracker>
              )}
              onChange={(_, selectedOption) => {
                checkOnMandatoryFields(InputFields.InsuranceType, selectedOption?.value);
                setFormState({ ...formState, insuranceType: selectedOption?.value ?? null });
              }}
            />
            <Autocomplete
              className='autocomplete autocomplete-custom'
              options={autocompleteIsLoading ? [] : filteredCarrierNames}
              autoComplete
              onInputChange={(_, value) => {
                setAutocompleteIsLoading(true);
                setCarrierNameFilter(value);
              }}
              loading={autocompleteIsLoading}
              renderInput={(params) => (
                <ElementTracker
                  routePath={routePath}
                  name='Insurance Name'
                  type={ElementTrackerType.Blurrable}>
                  <TextField
                    {...params}
                    placeholder='Insurance Name'
                    error={!!errors[InputFields.InsuranceCompany as keyof typeof errors]}
                    helperText={
                      !!errors[InputFields.InsuranceCompany as keyof typeof errors]
                        ? errors[InputFields.InsuranceCompany as keyof typeof errors]
                        : ''
                    }
                  />
                </ElementTracker>
              )}
              onChange={(_, selectedOption) => {
                checkOnMandatoryFields(InputFields.InsuranceCompany, selectedOption);
                setFormState({ ...formState, insuranceCompany: selectedOption ?? null });
              }}
            />
          </Stack>
        </Box>
        <Box>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <Box className='w-full'>
              <TextField
                id={InputFields.InsuranceId}
                label={'Member ID'}
                variant='filled'
                type={'text'}
                value={formState.insuranceId || ''}
                className='input w-full'
                onChange={handleChange}
                error={!!errors[InputFields.InsuranceId as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.InsuranceId as keyof typeof errors]
                    ? errors[InputFields.InsuranceId as keyof typeof errors]
                    : ''
                }
              />
            </Box>
            <Box className='w-full'>
              <TextField
                id={InputFields.GroupNumber}
                label={'Group ID'}
                variant='filled'
                type={'text'}
                value={formState.groupNumber || ''}
                className='input w-full'
                disabled={isGroupIdDisabled}
                onChange={handleChange}
                error={!!errors[InputFields.GroupNumber as keyof typeof errors]}
                helperText={
                  !!errors[InputFields.GroupNumber as keyof typeof errors]
                    ? errors[InputFields.GroupNumber as keyof typeof errors]
                    : ''
                }
              />
              <Box display={'flex'} justifyContent={'flex-start'} className='w-full'>
                <label>
                  <ElementTracker
                    routePath={routePath}
                    name="My insurance doesn't have Group ID"
                    type={ElementTrackerType.Checkable}>
                    <Checkbox name='no-groupid' onChange={handleBlockGroupIdInput} />
                  </ElementTracker>
                  <Typography variant='caption'>My insurance doesn't have Group ID</Typography>
                </label>
              </Box>
            </Box>
          </Stack>
        </Box>
        <Box>
          <div className='title-space'>
            <Typography paragraph variant='h4'>
              Add Insurance Card Photo
            </Typography>
          </div>
          <Stack direction={{ xs: 'column', xl: 'row' }} spacing={2}>
            <Box className='insurance-box'>{renderFileUploader('front')}</Box>
            <Box className='insurance-box'>{renderFileUploader('back')}</Box>
          </Stack>
        </Box>
        {!comingFromPhoneVerification && (
          <Box>
            <div className='title-space'>
              <Typography paragraph variant='h4'>
                Referring Provider
              </Typography>
            </div>
            <Autocomplete
              className='autocomplete autocomplete-custom'
              options={REFERRING_PROVIDER_OPTIONS}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={referringProviderDefaultValue}
              renderInput={(params) => (
                <ElementTracker
                  routePath={routePath}
                  name='Referring Provider'
                  type={ElementTrackerType.Blurrable}>
                  <TextField
                    {...params}
                    placeholder='Referring Provider'
                    error={!!errors[InputFields.ReferringProvider as keyof typeof errors]}
                    helperText={
                      !!errors[InputFields.ReferringProvider as keyof typeof errors]
                        ? errors[InputFields.ReferringProvider as keyof typeof errors]
                        : ''
                    }
                  />
                </ElementTracker>
              )}
              onChange={(_, value) => {
                checkOnMandatoryFields(InputFields.ReferringProvider, value?.id);
                setFormState({ ...formState, referringProvider: value?.id ?? null });
              }}
            />
          </Box>
        )}

        <Box>
          <Agreements
            ref={agreementsRef}
            agreementsMode={'public'}
            onLoaded={useCallback(() => setAgreementsLoaded(true), [])}
            onSelect={useCallback((all) => setAllAgreementsSelected(all), [])}
          />
        </Box>
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(NewMemberProfile, Page.NewMemberProfile);
