import {
  AVAILABLE_PLAN_TYPES,
  ElementTracker,
  ElementTrackerType,
  EligibilityResult,
  EligibilitySource,
  FeatureManager,
  LeadOnboardingStage,
  MixpanelClient,
  Payer,
  PlanType,
  VARIANT_GROUP_ID_CHECKBOX,
  eligibilityClient,
  isHPSMInsurance,
  onboardingClient,
  reportErrorToHoneybadger,
  useDebounce,
  userClient,
} from '@enaratech/funnel-helper';
import { Checkbox, Stack, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Box } from '@mui/system';
import {
  ChangeEvent,
  FC,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_USER_INSURANCE_COMPANY } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_ELIGIBILITY } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from '../routes';
import './scss/insurance.scss';

type FieldInfo = {
  value: string;
  withErrors: boolean;
};

const ManualInsurance: FC = (): ReactElement => {
  const [carrierInfo, setCarrierInfo] = useState<FieldInfo>({ value: '', withErrors: false });
  const [payers, setPayers] = useState<Payer[]>([]);
  const [selectedPayer, setSelectedPayer] = useState<Payer | null>(null);
  const [filteredCarrierNames, setFilteredCarrierNames] = useState<string[]>([]);
  const [carrierNameFilter, setCarrierNameFilter] = useState<string>('');
  const [autocompleteIsLoading, setAutocompleteIsLoading] = useState<boolean>(false);
  const [planTypeInfo, setPlanTypeInfo] = useState<FieldInfo>({ value: '', withErrors: false });
  const [memberIdInfo, setMemberIdInfo] = useState<FieldInfo>({ value: '', withErrors: false });
  const [groupId, setGroupId] = useState<string>('');
  const [isGroupIdDisabled, setIsGroupIdDisabled] = useState<boolean>(false);
  const [checkingEligibility, setCheckingEligibility] = useState<boolean>(false);

  const { clinicState } = useClinic();
  const {
    authState: { user },
    dispatchAuth,
  } = useAuth();
  const { dispatchOnboarding } = useOnboarding();

  const routePath = useRoutePath();

  const navigate = useNavigate();

  const handleCarrierChange = (_: SyntheticEvent<Element, Event>, value: string | null): void => {
    setCarrierInfo({ value: value || '', withErrors: !value });
    setSelectedPayer(payers.find((payer) => payer.name === value) || null);
  };

  const handlePlanTypeChange = (
    _: SyntheticEvent<Element, Event>,
    planType: { text: string; value: PlanType } | null
  ): void => {
    setPlanTypeInfo({ value: planType?.value || '', withErrors: !planType?.value });
  };

  const handleGroupIdChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setGroupId(e.target.value.trim().toUpperCase());
  };

  const handleMemberIdChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setMemberIdInfo({ value: e.target.value, withErrors: !e.target.value });
  };

  const handleBlockGroupIdInput = (): void => {
    setIsGroupIdDisabled((prev) => !prev);
  };

  const handleNext = async (): Promise<void> => {
    const planType = planTypeInfo.value as PlanType;

    onboardingClient.updateMemberStatus({
      onboardingStage: LeadOnboardingStage.ManualInsurance,
      ...(groupId && { groupId }),
      ...(planType !== 'none' && { planType }),
    });

    dispatchAuth({ type: SET_USER_INSURANCE_COMPANY, payload: carrierInfo.value });

    const isHPSM = isHPSMInsurance(carrierInfo.value);
    const shouldComputeEligibility = !isHPSM;

    let eligibilityResult: EligibilityResult | null = null;

    if (shouldComputeEligibility) {
      setCheckingEligibility(true);

      // If payerId is not defined, eligibility-API is capable to find it by payerName
      eligibilityResult = await eligibilityClient.getManualEligibility({
        groupId,
        payerId: selectedPayer?.id,
        payerName: selectedPayer?.name || carrierInfo.value,
        insuranceMemberId: memberIdInfo.value,
        manualPlanType: planType,
        source: EligibilitySource.Funnel,
        programType: user!.programType,
      });
    }

    MixpanelClient.setMetadata({
      ...(shouldComputeEligibility && { eligibility: !!eligibilityResult?.membershipType }),
      ...(shouldComputeEligibility &&
        !!eligibilityResult?.membershipType && {
          membershipType: eligibilityResult.membershipType,
        }),
      insuranceCarrier: carrierInfo.value,
      isHPSM,
    });

    userClient.updateUser({
      insuranceId: memberIdInfo.value,
      insuranceCompany: carrierInfo.value,
    });

    if (isHPSM) {
      navigateToPage({ targetPage: '/coverage', navigate });
    } else if (!!eligibilityResult?.membershipType) {
      dispatchOnboarding({ type: SET_ELIGIBILITY, payload: eligibilityResult.membershipType });

      navigateToPage({ targetPage: '/coverage', navigate });
    } else {
      reportErrorToHoneybadger({
        error: 'No eligibility match - Manual eligibility',
        isReportable: false,
      });

      navigateToPage({ targetPage: '/appointment/ms-booking', navigate });
    }
  };

  const fetchCarrierNames = useCallback(
    async ({
      page,
      clinicId,
      filter,
    }: {
      page: number;
      clinicId: number;
      filter?: string;
    }): Promise<void> => {
      const payers = await eligibilityClient.getPayerList({
        page,
        clinicId,
        ...(filter && { filter }),
      });

      setPayers(payers);
      setFilteredCarrierNames(payers.map((payer) => payer.name));
      setAutocompleteIsLoading(false);
    },
    []
  );

  const handleSearchCarrierName = useDebounce((filter: string) => {
    fetchCarrierNames({
      page: 1, // First version uses page 1 (not pagination)
      clinicId: clinicState!.details.clinicId,
      filter,
    });
  });

  useEffect(() => {
    handleSearchCarrierName(carrierNameFilter);
  }, [clinicState, handleSearchCarrierName, carrierNameFilter]);

  useEffect(() => {
    if (clinicState?.details.clinicId) {
      fetchCarrierNames({
        page: 1,
        clinicId: clinicState.details.clinicId,
      });
    }
  }, [clinicState, fetchCarrierNames]);

  return (
    <BasicLayout
      title={checkingEligibility ? 'Checking Eligibility' : 'Insurance Card Details'}
      {...(!checkingEligibility && {
        buttonProps: {
          disabled:
            !carrierInfo.value ||
            !planTypeInfo.value ||
            !memberIdInfo.value ||
            (FeatureManager.isVariantEnabled(VARIANT_GROUP_ID_CHECKBOX) &&
              !isGroupIdDisabled &&
              !groupId),
          onClick: handleNext,
        },
      })}
      back>
      <Stack display='flex' flexDirection={'column'} alignItems={'center'} spacing={3}>
        {checkingEligibility ? (
          <LoadingIndicator />
        ) : (
          <>
            <Typography className='manual-insurance-label w-full' variant={'h5'}>
              Carrier Name (Insurance Name)
            </Typography>
            <Autocomplete
              className='manual-insurance-input-field w-full'
              options={autocompleteIsLoading ? [] : filteredCarrierNames}
              autoComplete
              onInputChange={(_, value) => {
                setAutocompleteIsLoading(true);
                setCarrierNameFilter(value);
              }}
              loading={autocompleteIsLoading}
              renderInput={(params) => (
                <ElementTracker
                  routePath={routePath}
                  name='Carrier Name'
                  type={ElementTrackerType.Blurrable}>
                  <TextField
                    {...params}
                    placeholder='Carrier Name'
                    name='CarrierName'
                    error={carrierInfo.withErrors}
                    helperText={
                      carrierInfo.withErrors ? 'Carrier name is required to continue' : ''
                    }
                  />
                </ElementTracker>
              )}
              onChange={handleCarrierChange}
            />

            <Typography className='manual-insurance-label w-full' variant={'h5'}>
              Plan Type
            </Typography>
            <Autocomplete
              className='manual-insurance-input-field w-full'
              options={AVAILABLE_PLAN_TYPES}
              getOptionLabel={(option) => option.text}
              renderOption={(props, option) => <li {...props}>{option.text}</li>}
              renderInput={(params) => (
                <ElementTracker
                  routePath={routePath}
                  name='Plan type'
                  type={ElementTrackerType.Blurrable}>
                  <TextField
                    {...params}
                    placeholder='Select a Plan Type'
                    error={planTypeInfo.withErrors}
                    name='PlanType'
                    helperText={planTypeInfo.withErrors ? 'Plan type is required to continue' : ''}
                  />
                </ElementTracker>
              )}
              onChange={handlePlanTypeChange}
            />
            <Typography className='manual-insurance-label w-full' variant={'h5'}>
              Group ID / Number
            </Typography>
            <ElementTracker
              routePath={routePath}
              name='Group ID'
              type={ElementTrackerType.Blurrable}>
              <TextField
                placeholder='Your Group ID / Number'
                className='manual-insurance-input-field w-full'
                variant='filled'
                type={'text'}
                value={groupId}
                name='GroupId'
                disabled={isGroupIdDisabled}
                onChange={handleGroupIdChange}
              />
            </ElementTracker>
            {FeatureManager.isVariantEnabled(VARIANT_GROUP_ID_CHECKBOX) && (
              <Box display={'flex'} justifyContent={'flex-start'} className='w-full'>
                <label>
                  <ElementTracker
                    routePath={routePath}
                    name='Checkbox Group ID'
                    type={ElementTrackerType.Clickable}>
                    <Checkbox name='no-groupid' onChange={handleBlockGroupIdInput} />
                  </ElementTracker>
                  <Typography variant='caption'>My insurance doesn't have Group #</Typography>
                </label>
              </Box>
            )}
            <Typography className='manual-insurance-label w-full' variant={'h5'}>
              Member ID
            </Typography>
            <ElementTracker
              routePath={routePath}
              name='Member ID'
              type={ElementTrackerType.Blurrable}>
              <TextField
                placeholder='Your Member ID'
                className='manual-insurance-input-field w-full'
                variant='filled'
                type={'text'}
                name='MemberId'
                onChange={handleMemberIdChange}
                error={memberIdInfo.withErrors}
                helperText={memberIdInfo.withErrors ? 'Member ID is required to continue' : ''}
              />
            </ElementTracker>
          </>
        )}
      </Stack>
    </BasicLayout>
  );
};

export default withProgress(ManualInsurance, Page.ManualInsurance);
