import {
  CoverageInfo,
  CoverageInfoItem,
  ElementTracker,
  ElementTrackerType,
  FeatureManager,
  MembershipType,
  useViewport,
} from '@enaratech/funnel-helper';
import { Box, Collapse, Divider, FormControlLabelProps, Grid } from '@mui/material';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import ArrowExpandIcon from 'src/components/Common/Icons/ArrowExpandIcon';
import CheckIcon from 'src/components/Common/Icons/CheckIcon';
import EnaraListItemIcon from 'src/components/Common/Icons/EnaraListItemIcon';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { Data } from 'src/pages/Payment/Inputs/Payment.types';
import PromoInput from 'src/pages/Payment/Inputs/PromoInput/PromoInput';
import RadioButtons from '../Inputs/RadioButtons/RadioButtons';
import { displayDiscountMessage } from './helpers';
import { DiscountInfo, PlanTypes } from './paymentType.types';

type Props = {
  items: CoverageInfoItem[];
  plan: PlanTypes;
  prices: CoverageInfo['prices'];
  setPlan: (planType: PlanTypes) => void;
  planOptions: (Pick<FormControlLabelProps, 'label' | 'value'> & Partial<FormControlLabelProps>)[];
  discount: DiscountInfo;
  setPromo?: Dispatch<SetStateAction<string>>;
  setDiscount: Dispatch<SetStateAction<DiscountInfo>>;
  data: Data;
};

const PaymentType: FC<Props> = ({
  items,
  plan,
  prices,
  setPlan,
  planOptions,
  discount,
  setPromo,
  setDiscount,
  data,
}) => {
  const [visible, setVisible] = useState(false);
  const [annualDiscountEnabled, setAnnualDiscountEnabled] = useState<boolean>(false);

  const { isTabletView, isMobileView } = useViewport();
  const routePath = useRoutePath();

  const { clinicState } = useClinic();
  const {
    onboardingState: {
      eligibility: { membershipType },
    },
  } = useOnboarding();

  const handleChangePromo = (value: string, data: DiscountInfo) => {
    data.discountAnnually = Math.trunc(data.discountAnnually * 0.8);
    setDiscount(data);
    setPromo!(value);
  };

  useEffect(() => {
    (async () => {
      setAnnualDiscountEnabled(
        await FeatureManager.isAnnualDiscountEnabled(
          clinicState!.details.clinicId,
          membershipType ?? MembershipType.Cash
        )
      );
    })();
  }, []);

  return (
    <>
      {discount.coupon !== 0 && (
        <div className='details-discount-wrapper'>
          <div className='details-discount-message'>
            {displayDiscountMessage(plan, prices, discount)}
          </div>
        </div>
      )}
      <div className='details-payment'>
        <Box
          display='flex'
          justifyContent='center'
          flexWrap='wrap'
          columnGap={3}
          className='plan-payment'>
          {annualDiscountEnabled && (
            <>
              <div className='coverage-bubble'>
                {plan === 'annual' ? 'Best choice' : 'Save 20%'}
              </div>

              <RadioButtons
                name='Payment Plan'
                id='payment-plan'
                onChange={(e, value) => setPlan(value as PlanTypes)}
                options={planOptions}
                value={plan}
              />
            </>
          )}

          {setPromo && (
            <Grid item xs={12} sm={12} key={'promo-payment'} className='inputs-payment'>
              <PromoInput handleChange={handleChangePromo} data={data} />
            </Grid>
          )}
        </Box>
        <h3 className={`coverage-price ${discount.coupon ? 'coverage-discount' : ''}`}>
          {' '}
          {plan === 'monthly' ? `$${prices.monthly}/Month` : `$${prices.annual}/Year`}
        </h3>

        {discount.coupon !== 0 && (
          <h3 className='coverage-discount-new-price'>
            {plan === 'monthly'
              ? `$${
                  !Number.isInteger(discount.discountMonthly)
                    ? discount.discountMonthly.toFixed(2)
                    : discount.discountMonthly
                }/Month`
              : `$${
                  !Number.isInteger(discount.discountAnnually)
                    ? discount.discountAnnually.toFixed(2)
                    : discount.discountAnnually
                }/Year`}
          </h3>
        )}

        {(isTabletView || isMobileView) && (
          <>
            <Divider />
            <ElementTracker
              routePath={routePath}
              name='Plan coverage details'
              type={ElementTrackerType.Clickable}
              value={visible ? 'close' : 'open'}>
              <div className='coverage-open' onClick={() => setVisible(!visible)}>
                <ArrowExpandIcon
                  color='primary'
                  className={visible ? 'arrow-collapse' : 'arrow-expand'}
                />{' '}
                Membership includes
              </div>
            </ElementTracker>
          </>
        )}

        <Collapse in={isTabletView || isMobileView ? visible : true}>
          <div className='coverage-list-payment coverage-container-payment'>
            {!(isTabletView || isMobileView) && <h3>What includes:</h3>}
            <div
              onClick={() => {
                if (isTabletView || isMobileView) setVisible(!visible);
              }}>
              {items.map((item, i) => (
                <li key={`coverage-result-item-${i}`}>
                  <>
                    {item.coveredBy === 'enara' ? <EnaraListItemIcon /> : <CheckIcon />}
                    <span>{item.text}</span>
                  </>
                </li>
              ))}
            </div>
          </div>
          <Divider />
          <div className='coverage-legend-payment coverage-container-payment'>
            <li>
              <CheckIcon />
              <div>
                <span>Covered by your insurance </span>
                <span className='coverage-details'>(copays and deductibles may apply)</span>
              </div>
            </li>
            <li>
              <EnaraListItemIcon />
              <span>Included in your Enara Membership</span>
            </li>
            <li>
              <span className='coverage-details'>
                * Care credit is used to cover copays and deductibles for medical services. Care
                credit can not be used for lab deductibles.
              </span>
            </li>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default PaymentType;
