import {
  coreClient,
  deleteAuth,
  FeatureManager,
  FunnelErrors,
  getPartners,
  InputDateOfBirth,
  isFunnelError,
  isHPSMInsurance,
  LeadOnboardingStage,
  MixpanelClient,
  MixpanelEvent,
  onboardingClient,
  reportErrorToHoneybadger,
} from '@enaratech/funnel-helper';
import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DatePicker from 'src/components/Common/Inputs/DatePicker/DatePicker';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { Page } from 'src/components/Common/Progress/Progress';
import ProgressBar from 'src/components/Common/Progress/ProgressBar';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_AUTH_INFO } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic';
import { SET_CLINIC_INFO } from 'src/contexts/clinic/types';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_ONBOARDING_RESULT } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { navigateToPage } from '../routes';

const MAGIC_LINK_QUERY_PARAM = 'hash';

const MagicLink: FC = () => {
  const [dob, setDob] = useState<InputDateOfBirth>({ value: null });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [pullingInfo, setPullingInfo] = useState<boolean>(false);

  const { dispatchAuth } = useAuth();
  const { dispatchOnboarding } = useOnboarding();
  const { dispatchClinic } = useClinic();
  const routePath = useRoutePath();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resetPageWithErrorMessage = (errorMessage: string): void => {
    setPullingInfo(false);
    setIsSubmitting(false);

    deleteAuth();

    Toast.notification('error', errorMessage);
  };

  const handleClick = async (): Promise<void> => {
    setIsSubmitting(true);

    const hash = searchParams.get(MAGIC_LINK_QUERY_PARAM);

    if (!hash) {
      return resetPageWithErrorMessage('This is not a valid link');
    }

    const formattedDob = dob.value!.toFormat('yyyy-MM-dd');

    try {
      await onboardingClient.checkMemberMagicLinkHash(hash, formattedDob);
    } catch (error) {
      let errorMessage = 'This does not seem to be a valid link';

      if (isFunnelError(error, FunnelErrors.MagicLinkExpired)) {
        errorMessage = 'This link has already expired';

        MixpanelClient.trackEvent({
          event: MixpanelEvent.InputData,
          properties: { field: 'Link expired', value: hash, source: routePath },
        });
      } else if (isFunnelError(error, FunnelErrors.MagicLinkWrongDOB)) {
        errorMessage = 'The date of birth is not valid';

        MixpanelClient.trackEvent({
          event: MixpanelEvent.InputData,
          properties: { field: 'Wrong DOB', value: formattedDob, source: routePath },
        });
      }

      return resetPageWithErrorMessage(errorMessage);
    }

    setPullingInfo(true);

    let data = null;

    try {
      data = await coreClient.fetchAll();
    } catch (error) {
      reportErrorToHoneybadger({ error });

      if (isFunnelError(error, FunnelErrors.MembershipNotSet)) {
        MixpanelClient.trackEvent({
          event: MixpanelEvent.InputData,
          properties: { field: 'Membership not set', source: routePath },
        });
      }
    }

    if (!data || !data.user) {
      return resetPageWithErrorMessage(
        'We are still waiting for your insurance coverage to determine your membership alternatives. Please, reach out to our member success team for more information',
      );
    } else if (isHPSMInsurance(data.user.insuranceCompany)) {
      return resetPageWithErrorMessage(
        'Members from Health Plan of San Mateo are not allowed to go through this flow',
      );
    }

    const partners = await getPartners();

    const { user, onboarding, clinic, isReferral } = data;

    MixpanelClient.identifyUser(user.id);
    MixpanelClient.setMetadata({ magicLink: true });

    dispatchOnboarding({ type: SET_ONBOARDING_RESULT, payload: onboarding });
    dispatchClinic({ type: SET_CLINIC_INFO, payload: clinic ? { ...clinic, partners } : null });
    dispatchAuth({ type: SET_AUTH_INFO, payload: { loaded: true, isReferral, user } });

    const {
      eligibility,
      agreements: { agreementsHistory, status },
    } = onboarding;

    onboardingClient.updateMemberStatus({
      onboardingStage: LeadOnboardingStage.MagicLinkVerified,
    });

    if (
      FeatureManager.canSignCustomAgreements({
        agreementsHistory,
        agreementsStatus: status,
        membershipType: eligibility.membershipType,
        hintId: user.hintId,
      }) ||
      !user.hintId
    ) {
      navigateToPage({ targetPage: '/coverage', navigate });
    } else {
      // This is a returning member and we already have the payment info, redirecting
      // to '/goals' will cause the checker to take the member to the right place
      navigateToPage({ targetPage: '/goals', navigate });
    }
  };

  return pullingInfo ? (
    <div className='indicator-container'>
      <LoadingIndicator />
    </div>
  ) : (
    <>
      <ProgressBar currentPage={Page.MagicLink} allowUsersToLogin={false} />
      <BasicLayout
        title='Please enter your date of birth'
        subtitle='This will allow you to continue with the onboarding process'
        buttonProps={{
          disabled: isSubmitting || !dob.value || dob.error,
          loading: isSubmitting,
          onClick: handleClick,
        }}>
        <DatePicker
          error={dob.error}
          errorMessage={dob.errorMessage}
          onChange={(date: DateTime | null) => setDob({ value: date })}
          value={dob.value}
        />
      </BasicLayout>
    </>
  );
};

export default MagicLink;
