import { CoverageInfo, crmClient, getParsedCoverageInfo } from '@enaratech/funnel-helper';
import { Grid, Stack } from '@mui/material';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import CoverageResult from '../Payment/CoverageResult';
import {
  ANNUAL_PLAN_DURATION,
  MONTHLY_PLAN_DURATION,
  ReturnData,
} from '../Payment/Inputs/Payment.types';
import { PLAN_OPTIONS } from '../Payment/Inputs/inputFields';
import { navigateToPage } from '../routes';

const CustomAgreements: FC = () => {
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);
  const [plan, setPlan] = useState<number>(MONTHLY_PLAN_DURATION);
  const [coverage, setCoverage] = useState<CoverageInfo | null>(null);
  const [discount, setDiscount] = useState<ReturnData>({
    discountMonthly: 0,
    discountAnnually: 0,
    coupon: 0,
    duration: 0,
  });

  const agreementsRef = useRef<RefAgreements>(null);
  const navigate = useNavigate();

  const { clinicState } = useClinic();
  const {
    authState: { user },
  } = useAuth();

  const {
    onboardingState: {
      eligibility: { membershipType },
    },
  } = useOnboarding();

  const getInitialPrice = () => {
    return plan === MONTHLY_PLAN_DURATION
      ? `${coverage?.prices.monthly}`
      : `${coverage?.prices.annual}`;
  };

  const getDiscountPrice = () => {
    return plan === MONTHLY_PLAN_DURATION
      ? `${discount.discountMonthly}`
      : `${discount.discountAnnually}`;
  };

  const handleClick = (): void => {
    if (agreementsRef.current) {
      agreementsRef.current.signAgreements();
    }

    crmClient.updateLead({
      uuid: user!.uuid,
      shownPrice: getInitialPrice(),
      finalPrice: discount.coupon !== 0 ? getDiscountPrice() : getInitialPrice(),
      billingFrequency: plan === ANNUAL_PLAN_DURATION ? 'yearly' : 'monthly',
    });

    navigateToPage({ targetPage: '/appointment/self-serve-booking', navigate });
  };

  const handleGetCoverage = async () => {
    const coverageResponse = await getParsedCoverageInfo({
      membershipType,
      insuranceCompany: user!.insuranceCompany,
      programType: user!.programType,
      clinicId: user!.clinicId,
    });

    setCoverage(coverageResponse);
  };

  useEffect(() => {
    handleGetCoverage();
  }, []);

  return (
    <BasicLayout
      title={'Please review and sign your updated terms to continue using Enara'}
      subtitle={'You won’t be able to continue using your Enara account until you sign the terms'}
      buttonProps={{ disabled: !agreementsLoaded || !allAgreementsSelected, onClick: handleClick }}
      className='container-payment'>
      <Grid container justifyContent={'space-around'} direction={'row-reverse'}>
        <Grid item md={12} lg={5} xl={4}>
          {coverage && (
            <CoverageResult
              addPlan={setPlan}
              coverage={coverage}
              planOptions={PLAN_OPTIONS}
              discount={discount}
              setDiscount={setDiscount}
            />
          )}
        </Grid>
        <Grid item md={12} lg={6} xl={7}>
          <Stack>
            <Agreements
              ref={agreementsRef}
              agreementsMode={'needs-auth'}
              onLoaded={useCallback(() => setAgreementsLoaded(true), [])}
              onSelect={useCallback((all) => setAllAgreementsSelected(all), [])}
            />
          </Stack>
        </Grid>
      </Grid>
    </BasicLayout>
  );
};

export default withProgress(CustomAgreements, Page.CustomAgreements);
