import {
  LeadSystemSource,
  ProgramType,
  scheduleClient,
  SchedulingWorkflow,
  Step,
  User,
} from '@enaratech/funnel-helper';
import { Dispatch } from 'react';
import Toast from 'src/components/Common/Toast/Toast';
import { ClinicState } from 'src/contexts/clinic/types';
import { SET_SSB_INFO, SSBActionTypes, SSBFlowState } from 'src/contexts/ssb/types';
import { capitalizeToKebabCase } from 'src/utils/array';
import { getPreviousAppointmentByRules } from './fetchStatusAction';

export const goToNextStepAction = async ({
  context,
  setState,
}: {
  context: {
    stepsRef: React.MutableRefObject<Step[] | null>;
    scheduledAppointments: any;
    user: User;
    clinic: ClinicState;
    appointmentsSchedulerRef: any;
    routePath: any;
    selectedLanguage: any;
  };
  setState: Dispatch<SSBActionTypes>;
}) => {
  const { scheduledAppointments, stepsRef, selectedLanguage, appointmentsSchedulerRef } = context;

  // TODO: Avoid this call, this can be calculated before
  const fetchedSteps = await scheduleClient.getStepsForMember({
    clinicId: context.clinic!.details.clinicId,
    memberId: context.user!.id,
    programType: context.user!.programType as ProgramType.InClinic | ProgramType.Remote,
    systemSource: capitalizeToKebabCase(context.user!.systemSource) as LeadSystemSource,
    schedulingWorkflow: SchedulingWorkflow.SelfServeBooking,
  });

  if (!fetchedSteps) {
    return Toast.notification(
      'error',
      'There was an error obtaining the information. Please reload the page or wait for a support member to contact you, we are looking for the solution.'
    );
  }

  context.stepsRef.current = fetchedSteps;

  if (fetchedSteps?.every((step) => step.scheduled)) {
    setState({ type: SET_SSB_INFO, payload: SSBFlowState.AllScheduled });
    return;
  }

  const previousAppointment = getPreviousAppointmentByRules({
    scheduledAppointments,
    stepsRef,
    selectedLanguage,
    calculatedSteps: fetchedSteps,
  });

  appointmentsSchedulerRef.current?.resetCalendar(previousAppointment);
  setState({ type: SET_SSB_INFO, payload: SSBFlowState.FetchSchedulingStatus });
};
