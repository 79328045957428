import {
  FeatureManager,
  getAnonymousProgress,
  identifyUserInHoneybadger,
  isHPSMInsurance,
  isLowBMI,
  MixpanelClient,
  notCovered,
  ProgramType,
  ProgressKey,
  StatsigManager,
} from '@enaratech/funnel-helper';
import { FC, ReactNode, useEffect, useState } from 'react';
import { NavigationType, useNavigate, useNavigationType } from 'react-router-dom';
import { useApp } from 'src/contexts/app';
import { useAuth } from 'src/contexts/auth';
import { useClinic } from 'src/contexts/clinic';
import { useExperiment } from 'src/contexts/experiments';
import { useOnboarding } from 'src/contexts/onboarding';
import { navigateToPage } from 'src/pages/routes';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import {
  checkCustomAgreementsFlow,
  checkEligibilityFlow,
  checkLegacyFunnelMemberFlow,
  checkMagicLinkOrReferralFlow,
  checkMemberSuccessFlow,
  checkNoInsuranceFlow,
} from './flowRules';
import ProgressBar from './ProgressBar';
import './scss/progress.scss';

export enum Page {
  Goals,
  GetStarted,
  LowBMI,
  DateOfBirth,
  Partners,
  NotAvailable,
  CreateAccount,
  Insurance,
  ManualInsurance,
  Coverage,
  Payment,
  SelfServeBooking,
  SelfServeBookingResult,
  MemberSuccessBooking,
  MemberSuccessBookingResult,
  MagicLink,
  Referrals,
  CustomAgreements,
  ComingCallResult,
}

type Props = {
  page: Page;
  children: ReactNode;
};

const Progress: FC<Props> = ({ page, children }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const navigationAction = useNavigationType();

  const { canNavigateBetweenPages, shouldSkipPartnerPage, allowUserToNavigateBetweenPages } =
    useApp();
  const { authState } = useAuth();
  const { clinicState } = useClinic();
  const { onboardingState } = useOnboarding();
  const {
    experimentState: { isInitialized },
  } = useExperiment();

  useEffect(() => {
    (async () => {
      const { loaded, isReferral, user } = authState;

      if (!loaded || !isInitialized) {
        return;
      }

      // If going to the previous page, allow the action
      if (navigationAction === NavigationType.Replace) {
        return setLoading(false);
      }

      if (clinicState?.program === ProgramType.NotCovered) {
        if (page !== Page.NotAvailable && canNavigateBetweenPages) {
          return navigateToPage({ targetPage: '/not-available', navigate });
        } else {
          return setLoading(false);
        }
      }

      if (user) {
        identifyUserInHoneybadger(user.id, user.email);

        const {
          agreements: { agreementsHistory, status },
          eligibility,
          bookingMethod,
          locationFlow,
          magicLink,
        } = onboardingState;
        const { details } = clinicState!;

        // As of today, we have three different ways to create an account (old, new and referrals funnel) and
        // we need the clinic ID to be set as user prop because it help us to build metrics in mixpanel. So
        // for any member (returning or new one) we will always set this field globally
        MixpanelClient.setMetadata({ clinicId: details.clinicId });

        const shouldSignCustomAgreements = FeatureManager.canSignCustomAgreements({
          agreementsHistory,
          agreementsStatus: status,
          membershipType: eligibility.membershipType,
          hintId: user.hintId,
        });

        // Coverage page requires no input from the member to be displayed, however, we must not
        // allow all of them to land here, else, they could be seeing something that's not accurate
        if (
          page === Page.Coverage &&
          (!user.hintId ||
            notCovered(user.insuranceOutcome) ||
            eligibility.membershipType ||
            shouldSignCustomAgreements)
        ) {
          return setLoading(false);
        }

        // Insurance information has not been completed yet
        // We may safely skip this step for referrals as the insurance information is collected in a different way
        if (!isReferral && !user.insuranceCompany && !eligibility.membershipType) {
          if (page === Page.ManualInsurance) {
            return setLoading(false);
          } else if (StatsigManager.isArrivingFromAdwords()) {
            // Users have arrived to the funnel through Google Ads, so they need to be redirected to MS Call Screen
            if (
              !checkMemberSuccessFlow({
                page,
                initialAppointmentId: user.initialAppointmentId,
                canNavigateBetweenPages,
                navigate,
              })
            ) {
              return;
            }
          } else if (page !== Page.Insurance && canNavigateBetweenPages) {
            return navigateToPage({ targetPage: '/insurance', navigate });
          }
        }
        // HPSM (Health Plan of San Mateo) has no cost at all, so users can go directly to the MS flow
        else if (isHPSMInsurance(user.insuranceCompany)) {
          if (
            !checkMemberSuccessFlow({
              page,
              initialAppointmentId: user.initialAppointmentId,
              canNavigateBetweenPages,
              navigate,
            })
          ) {
            return;
          }
        }
        // Member was created in the legacy funnel but needs to be able to finish the onboarding process in the new one
        else if (
          FeatureManager.qualifiesToContinueWithOnboardingProcess({
            initialAppointmentId: user.initialAppointmentId,
            hintId: user.hintId,
          })
        ) {
          if (
            !checkLegacyFunnelMemberFlow({
              page,
              initialAppointmentId: user.initialAppointmentId,
              membershipType: eligibility.membershipType,
              canNavigateBetweenPages,
              navigate,
            })
          ) {
            return;
          }
        }
        // Members might or might not signed agreements BUT the ops team decided to provide new prices (membership), hence -> sign agreements again
        else if (shouldSignCustomAgreements) {
          if (
            !checkCustomAgreementsFlow({
              page,
              hintId: user.hintId,
              initialAppointmentId: user.initialAppointmentId,
              status,
              locationFlow,
              bookingMethod,
              canNavigateBetweenPages,
              isSelfServeBookingFeatureEnabled:
                (locationFlow &&
                  FeatureManager.shouldForceGoToSSBForReferralHubFlow(locationFlow)) ||
                (await FeatureManager.isSelfServeBookingFeatureEnabled(
                  details.clinicId,
                  user.insuranceCompany
                )),
              navigate,
            })
          ) {
            return;
          }
        }
        // There are two different cases where we want to force the SSB flow:
        // Returning member (Received an SMS with a magic link). Previously, MS -> now SSB
        // So far, all referrals from Kafri will go directly through the SSB flow
        else if (
          (locationFlow && FeatureManager.shouldForceGoToSSBForReferralHubFlow(locationFlow)) ||
          magicLink ||
          FeatureManager.shouldReferralGoThroughSSB({ isReferral, clinicId: details.clinicId })
        ) {
          if (
            !checkMagicLinkOrReferralFlow({
              page,
              hintId: user.hintId,
              locationFlow,
              bookingMethod,
              canNavigateBetweenPages,
              navigate,
            })
          ) {
            return;
          }
        }
        // All non-Kafri referrals will always go through the MS flow (first pass, then -> magic link)
        else if (isReferral) {
          if (
            !checkMemberSuccessFlow({
              page,
              initialAppointmentId: user.initialAppointmentId,
              canNavigateBetweenPages,
              navigate,
            })
          ) {
            return;
          }
        }
        // Did match against the eligibility db so users have to go through either the SSB or MS flow
        else if (eligibility.membershipType) {
          if (
            !checkEligibilityFlow({
              page,
              hintId: user.hintId,
              initialAppointmentId: user.initialAppointmentId,
              locationFlow,
              bookingMethod,
              canNavigateBetweenPages,
              isSelfServeBookingFeatureEnabled:
                (locationFlow &&
                  FeatureManager.shouldForceGoToSSBForReferralHubFlow(locationFlow)) ||
                (await FeatureManager.isSelfServeBookingFeatureEnabled(
                  details.clinicId,
                  user.insuranceCompany
                )),
              navigate,
            })
          ) {
            return;
          }
        }
        // Users have no insurance (coverage = cash) so they need to be charged and then go to SSB flow if enabled
        else if (notCovered(user.insuranceOutcome)) {
          if (
            !checkNoInsuranceFlow({
              page,
              hintId: user.hintId,
              locationFlow,
              bookingMethod,
              canNavigateBetweenPages,
              initialAppointmentId: user.initialAppointmentId,
              isSelfServeBookingFeatureEnabled:
                (locationFlow &&
                  FeatureManager.shouldForceGoToSSBForReferralHubFlow(locationFlow)) ||
                (await FeatureManager.isSelfServeBookingFeatureEnabled(
                  details.clinicId,
                  user.insuranceCompany
                )),
              navigate,
            })
          ) {
            return;
          }
        } else {
          // We need to know a little bit more about the new member -> MS flow is the way to go
          if (
            !checkMemberSuccessFlow({
              page,
              initialAppointmentId: user.initialAppointmentId,
              canNavigateBetweenPages,
              navigate,
            })
          ) {
            return;
          }
        }
      } else {
        const anonymousProgress = getAnonymousProgress();

        if (!anonymousProgress) {
          if (page !== Page.Goals) {
            return navigateToPage({ targetPage: '/goals', navigate });
          }
        } else {
          const keys = Object.keys(anonymousProgress || {}) as ProgressKey[];
          const allTracked = keys.length === Object.keys(ProgressKey).length;

          if (allTracked) {
            if (page !== Page.CreateAccount && canNavigateBetweenPages) {
              return navigateToPage({ targetPage: '/create-account', navigate });
            }
          }

          const { weightLbs, heightFeet, heightInches } =
            anonymousProgress[ProgressKey.GetStarted] || {};

          if (!keys.includes(ProgressKey.ProgramGoals)) {
            if (page !== Page.Goals && canNavigateBetweenPages) {
              return navigateToPage({ targetPage: '/goals', navigate });
            }
          } else if (!keys.includes(ProgressKey.GetStarted)) {
            if (page !== Page.GetStarted && canNavigateBetweenPages) {
              return navigateToPage({ targetPage: '/get-started', navigate });
            }
          } else if (
            isLowBMI({
              weight: +weightLbs,
              feet: +heightFeet,
              inches: +heightInches,
            })
          ) {
            if (page !== Page.LowBMI && canNavigateBetweenPages) {
              return navigateToPage({ targetPage: '/low-bmi', navigate });
            }
          } else if (!keys.includes(ProgressKey.DateOfBirth)) {
            if (page !== Page.DateOfBirth && canNavigateBetweenPages) {
              return navigateToPage({ targetPage: '/about', navigate });
            }
          } else if (!shouldSkipPartnerPage && !keys.includes(ProgressKey.Partner)) {
            if (page !== Page.Partners && canNavigateBetweenPages) {
              return navigateToPage({ targetPage: '/partners', navigate });
            }
          }
        }
      }

      setLoading(false);
      allowUserToNavigateBetweenPages();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, page, navigationAction, authState, canNavigateBetweenPages, isInitialized]);

  return loading ? (
    <div className='indicator-container w-full'>
      <LoadingIndicator />
    </div>
  ) : (
    <>
      <ProgressBar currentPage={page} />
      {children}
    </>
  );
};

export const withProgress =
  (WrappedComponent: FC, page: Page): FC =>
  () => {
    return (
      <Progress page={page}>
        <WrappedComponent />
      </Progress>
    );
  };
