export interface DiscountInfo {
  discountMonthly: number;
  discountAnnually: number;
  coupon: number;
  duration: number;
}

export enum PlanTypes {
  ANNUAL = 'annual',
  MONTHLY = 'monthly',
}

export enum PlanTypesMapping {
  'annual' = 12,
  'monthly' = 1,
}
