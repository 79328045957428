import {
  LOCATION_SELECTOR_OPTIONS,
  SupportedLocationType,
  getPartnerById,
} from '@enaratech/funnel-helper';
import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';
import RadioButtons from 'src/components/Common/Inputs/RadioButtons/RadioButtons';
import { useClinic } from 'src/contexts/clinic';

export const RADIO_LOCATION_SELECTOR_OPTIONS = [
  {
    label: 'In Person',
    value: 'IP' as SupportedLocationType,
    disabled: false,
  },
  {
    label: 'Online',
    value: 'OL' as SupportedLocationType,
    disabled: false,
  },
];

export enum LocationSelectorType {
  Radio = 'radio',
  Selector = 'selector',
}

export type RadioSelectorOptions = {
  label: string;
  value: SupportedLocationType;
  disabled: boolean;
};

type Props = {
  onChange: React.Dispatch<React.SetStateAction<SupportedLocationType | undefined>>;
  selectedLocation: SupportedLocationType | undefined;
  type: LocationSelectorType;
  options: RadioSelectorOptions[];
};

const LocationSelector: FC<Props> = ({
  onChange,
  selectedLocation,
  type = LocationSelectorType.Selector,
  options,
}) => {
  const [location, setLocation] = useState<SupportedLocationType>('IP');
  const { clinicState } = useClinic();

  const getLocationOptionByLocation = (location: SupportedLocationType) => {
    return options.find(({ value }) => value === location);
  };

  return (
    <>
      {type === LocationSelectorType.Selector && (
        <Box>
          <Typography>What's your preferred appointment type</Typography>

          <Autocomplete
            sx={{ marginBottom: '25px' }}
            className='autocomplete'
            options={LOCATION_SELECTOR_OPTIONS}
            getOptionLabel={(option) => option.label}
            value={selectedLocation && getLocationOptionByLocation(selectedLocation)}
            renderInput={(params) => <TextField {...params} placeholder='Please select' />}
            onChange={(_, option) => {
              if (option) {
                setLocation(option.value);
                onChange(option.value);
              }
            }}
          />
        </Box>
      )}

      {type === LocationSelectorType.Radio && (
        <Box>
          <Typography mb={1}>How do you prefer to meet?</Typography>

          <RadioButtons
            name='programType'
            id='location-selector-program-type'
            onChange={(e, value) => onChange(value as SupportedLocationType)}
            options={options}
            value={selectedLocation}
            row
            sx={{
              marginBottom: '1.5em',
            }}
          />

          {location === 'OL' && (
            <Typography>
              You will be meeting with your Member Care and Growth Manager to address together any
              inquiries you might have about our program.
            </Typography>
          )}

          {location === 'IP' && (
            <Typography>
              You will be meeting with your Member Care and Growth Manager to address together any
              inquiries you might have about our program.{' '}
              {getPartnerById(clinicState!.details.clinicId, clinicState!.partners)?.name} and will
              be happy to guide you through the process!
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default LocationSelector;
