import {
  AgreementWithDetails,
  CircleCancelIcon,
  ElementTracker,
  ElementTrackerType,
  HIPAA_AGREEMENTS_TOKEN,
  PublicAgreement,
  RawAgreement,
  handleRetry,
  isHPSMInsurance,
  onboardingClient,
  reportErrorToHoneybadger,
} from '@enaratech/funnel-helper';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import {
  ChangeEvent,
  ForwardRefRenderFunction,
  ForwardedRef,
  MouseEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import { useAuth } from 'src/contexts/auth';
import { useClinic } from 'src/contexts/clinic';
import { useOnboarding } from 'src/contexts/onboarding';
import { useRoutePath } from 'src/hooks/useRoutePath';
import './scss/agreements.scss';

const AGREEMENTS_NUMBER_OF_SECTIONS = 1;

export type RefAgreements = {
  signAgreements: () => Promise<void>;
};

type Props = {
  agreementsMode: 'public' | 'needs-auth';
  address?: string;
  className?: string;
  onLoaded: () => void;
  onSelect: (all: boolean) => void;
};

const Agreements: ForwardRefRenderFunction<RefAgreements, Props> = (
  { agreementsMode, address, className, onLoaded, onSelect },
  ref: ForwardedRef<RefAgreements>
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [agreements, setAgreements] = useState<RawAgreement[] | null>(null);
  const [publicAgreements, setPublicAgreements] = useState<PublicAgreement[] | null>(null);
  const [details, setDetails] = useState<{
    loading: boolean;
    show: boolean;
    error: boolean;
    agreement: AgreementWithDetails | null;
  }>({
    loading: false,
    show: false,
    error: false,
    agreement: null,
  });

  const routePath = useRoutePath();
  const {
    authState: { user },
  } = useAuth();

  const { clinicState } = useClinic();
  const { onboardingState } = useOnboarding();

  const selectionCountRef = useRef<number>(AGREEMENTS_NUMBER_OF_SECTIONS);

  const markAgreementAsAccepted = async (
    agreement: RawAgreement | PublicAgreement
  ): Promise<boolean> => {
    if (agreementsMode === 'needs-auth') {
      const { membershipType } = onboardingState.eligibility;

      return await onboardingClient.markAgreementAsAccepted({
        user: user!,
        clinic: clinicState!,
        address,
        agreementCode: agreement.code,
        membershipType,
        insuranceOutcome: user!.insuranceOutcome,
      });
    }

    const publicAgreement = agreement as PublicAgreement;

    return await onboardingClient.markPublicAgreementAsAccepted({
      clinic: clinicState!,
      insuranceCode: publicAgreement.insurance,
      agreementCode: publicAgreement.code,
    });
  };

  useImperativeHandle(ref, () => ({
    signAgreements: async (): Promise<void> => {
      const agreementsToRender = agreements ?? publicAgreements;

      if (!agreementsToRender) {
        return reportErrorToHoneybadger({ error: 'No agreements available for user to sign' });
      }

      const promises = agreementsToRender.map((agreement) =>
        handleRetry(async () => {
          const success = await markAgreementAsAccepted(agreement);

          // Throw is required by handleRetry function to understand the fail and retry the request again
          if (!success) {
            throw new Error('Agreement could not be signed');
          }
        })
      );

      Promise.all(promises).then((results) => {
        results.map(({ success }, index) => {
          if (!success) {
            const agreement = agreementsToRender[index];

            // Send a silent error to HoneyBadger without stopping the flow
            reportErrorToHoneybadger({
              error: `Agreement ${agreement.code} could not be signed`,
            });
          }

          return success;
        });
      });
    },
  }));

  useEffect(() => {
    (async () => {
      if (agreementsMode === 'needs-auth') {
        const { membershipType } = onboardingState.eligibility;

        const requiredAgreements = await onboardingClient.listAgreementsForUser({
          user: user!,
          clinic: clinicState!,
          membershipType,
          insuranceOutcome: user!.insuranceOutcome,
        });

        const signedAgreements = await onboardingClient.listMyUserAgreements();

        const shouldFilterAlreadySignedAgreements =
          !!requiredAgreements?.length && !!signedAgreements?.length;

        setAgreements(
          shouldFilterAlreadySignedAgreements
            ? requiredAgreements.filter((ra) => signedAgreements.some((sa) => ra.code !== sa.code))
            : requiredAgreements
        );
      } else {
        setPublicAgreements(await onboardingClient.listPublicAgreements(clinicState!));
      }

      onLoaded();
      onSelect(true);

      setLoading(false);
    })();
  }, [agreementsMode, user, clinicState, onboardingState, onLoaded, onSelect]);

  const handleSectionSelection = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    selectionCountRef.current = target.checked
      ? selectionCountRef.current - 1
      : selectionCountRef.current + 1;

    onSelect(selectionCountRef.current === AGREEMENTS_NUMBER_OF_SECTIONS);
  };

  const handleShowAgreementDetails = async (
    event: MouseEvent<HTMLAnchorElement>,
    agreement: RawAgreement | PublicAgreement
  ): Promise<void> => {
    event.preventDefault();

    setDetails({ ...details, loading: true, show: true });

    let agreementWithDetails: AgreementWithDetails | null = null;

    if (agreementsMode === 'needs-auth') {
      const { membershipType } = onboardingState.eligibility;

      agreementWithDetails = await onboardingClient.fetchAgreement({
        user: user!,
        clinic: clinicState!,
        address,
        agreementCode: agreement.code,
        membershipType,
        insuranceOutcome: user!.insuranceOutcome,
      });
    } else {
      const publicAgreement = agreement as PublicAgreement;

      agreementWithDetails = await onboardingClient.fetchPublicAgreement({
        clinic: clinicState!,
        insuranceCode: publicAgreement.insurance,
        agreementCode: publicAgreement.code,
      });
    }

    setDetails((prev) => ({
      ...prev,
      loading: false,
      error: !agreementWithDetails,
      agreement: agreementWithDetails,
    }));
  };

  const handleCloseDialog = (): void => {
    setDetails({ loading: false, show: false, error: false, agreement: null });
  };

  const handleExportAgreement = (): void => {
    const html = document.getElementById('agreement-content') as HTMLElement;

    const a = window.open() as Window;

    a.document.write('<html>');
    a.document.write('<body>');
    a.document.write(html.innerHTML);
    a.document.write('</body></html>');
    a.document.close();
    a.print();
  };

  const renderAgreementsSection = (
    title: string,
    agreements: RawAgreement[] | PublicAgreement[]
  ): ReactNode => {
    if (agreements.length === 0) {
      return null;
    }

    return (
      <FormControlLabel
        control={
          <ElementTracker routePath={routePath} name={title} type={ElementTrackerType.Checkable}>
            <Checkbox onChange={handleSectionSelection} defaultChecked />
          </ElementTracker>
        }
        label={
          <div>
            {title}{' '}
            {agreements.map((a, i) => [
              <ElementTracker
                routePath={routePath}
                key={`agreements-link-${a.code}`}
                name='Open Agreement'
                value={a.code}
                type={ElementTrackerType.Clickable}>
                <Link
                  href='#'
                  onClick={(e: MouseEvent<HTMLAnchorElement>) => handleShowAgreementDetails(e, a)}>
                  {a.title}
                </Link>
              </ElementTracker>,
              agreements.length > 1 && i < agreements.length - 1 ? (
                <span key={`agreements-padding-${a.code}`}>
                  {i === agreements.length - 2 ? ' and ' : ', '}
                </span>
              ) : undefined,
            ])}
          </div>
        }
      />
    );
  };

  if (loading) {
    return null;
  }

  const agreementsToRender = agreements ?? publicAgreements;

  return agreementsToRender ? (
    <>
      <Dialog
        onClose={handleCloseDialog}
        open={details.show}
        fullWidth
        className='agreements-dialog'>
        {details.loading ? (
          <div className='agreement-details-loading'>
            <CircularProgress />
          </div>
        ) : details.error ? (
          <DialogTitle>There was an error fetching the agreement, please try again.</DialogTitle>
        ) : (
          <>
            <DialogTitle>
              <ElementTracker
                routePath={routePath}
                name='Close Agreement'
                type={ElementTrackerType.Clickable}>
                <CircleCancelIcon
                  onClick={handleCloseDialog}
                  className='agreement-dialog-close-button'
                />
              </ElementTracker>

              <Typography variant='h3' align='center'>
                {details.agreement?.title}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Box id={'agreement-content'} padding={2}>
                <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>
                  {details.agreement?.content.replace(/\n$/, '') || ''}
                </ReactMarkdown>
              </Box>
            </DialogContent>
            <DialogActions>
              <ElementTracker
                routePath={routePath}
                name='Export PDF button'
                type={ElementTrackerType.Clickable}>
                <Button variant='contained' onClick={handleExportAgreement} fullWidth>
                  Export PDF
                </Button>
              </ElementTracker>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Stack className={`agreements-payment ${className}`}>
        {agreementsMode === 'public' || isHPSMInsurance(user?.insuranceCompany || '')
          ? renderAgreementsSection(
              'I acknowledge receipt of the following: ',
              agreementsToRender.filter(
                (a) => a.code.toLowerCase().indexOf(HIPAA_AGREEMENTS_TOKEN) !== -1
              )
            )
          : renderAgreementsSection(
              'I’m at least 18 years of age and I read and accept ',
              agreementsToRender.filter(
                (a) => a.code.toLowerCase().indexOf(HIPAA_AGREEMENTS_TOKEN) === -1
              )
            )}
      </Stack>
    </>
  ) : null;
};

export default forwardRef(Agreements);
