/* eslint-disable react-hooks/exhaustive-deps */
import {
  AvailableLanguages,
  AvailableTimeSlotData,
  DatesWithPrioritizedProviders,
  FeatureManager,
  isKafriClinic,
  LeadSystemSource,
  ProgramType,
  ProviderRole,
  scheduleClient,
  ScheduledAppointments,
  SchedulingWorkflow,
  Step,
  SupportedAppointmentIntention,
  SupportedLocationType,
} from '@enaratech/funnel-helper';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth/index';
import { useClinic } from 'src/contexts/clinic/index';
import { useOnboarding } from 'src/contexts/onboarding';
import { useSSB } from 'src/contexts/ssb';
import { SET_SSB_INFO, SSBFlowState } from 'src/contexts/ssb/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { CacheDuration, withCache } from 'src/lib/local-cache';
import { capitalizeToKebabCase } from 'src/utils/array';
import AppointmentsScheduler, {
  AppointmentsSchedulerRef,
} from '../Scheduler/AppointmentsScheduler/AppointmentsScheduler';
import { ChangeLanguageLink } from './ChangeLanguageLink';
import { DEFAULT_DAYS_TO_SKIP_BETWEEN_APPOINTMENTS } from './constants';
import LanguageSelector, { getLanguageName } from './LanguageSelector';
import LocationSelector, {
  LocationSelectorType,
  RADIO_LOCATION_SELECTOR_OPTIONS,
} from './LocationSelector';
import { cleanAndFormatSlots, formatPrioritizedProvidersByDates } from './rules/appointments';
import {
  configureLocationAndSelectorVisibility,
  getPreviousAppointmentByRules,
} from './rules/ssbFlow/actions/fetchStatusAction';
import { evaluateCurrentState } from './rules/ssbFlow/flow';
import { AvailableProvider } from './rules/ssbFlow/types';
import { SchedulingSuggestion } from './ScheduleSuggestion';
import './scss/selfServeBooking.scss';
import SSBTitle from './SSBTitle';

const MIN_DAYS_TO_DISPLAY_ALERT = 5;

export const SelfServeBooking: FC = () => {
  const [selectedTimeSlotKey, setSelectedTimeSlotKey] = useState<string | null>(null); // timeSlot-acuityCalendarId
  const [scheduledAppointments, setScheduledAppointments] = useState<ScheduledAppointments | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [pullingAppointments, setPullingAppointments] = useState<boolean>(true);
  const [availableProviders, setAvailableProviders] = useState<AvailableProvider[] | null>(null);
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState<boolean>(true);
  const [scheduleSuggestionIsOpen, setScheduleSuggestionIsOpen] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<AvailableLanguages | null>(null);
  const [isEnabledBasedOnSpecialty, setIsEnabledBasedOnSpecialty] = useState<boolean>(true);
  const [thereIsNoAvailability, setThereIsNoAvailability] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<SupportedLocationType | undefined>('OL');

  const appointmentsSchedulerRef = useRef<AppointmentsSchedulerRef | null>(null);
  const stepsRef = useRef<Step[] | null>(null);

  const navigate = useNavigate();

  const {
    authState: { user },
  } = useAuth();

  const { ssbState, dispatchSSB } = useSSB();

  const { clinicState } = useClinic();
  const { dispatchOnboarding } = useOnboarding();
  const routePath = useRoutePath();

  const choseLocationBySpecialtyOrIntention = (
    specialty: ProviderRole,
    intention: SupportedAppointmentIntention,
    defaultValue?: SupportedLocationType
  ): SupportedLocationType | undefined => {
    if ([intention, specialty].includes('GC' as SupportedAppointmentIntention)) {
      return 'OL' as SupportedLocationType;
    }

    if (specialty === ('NS' as ProviderRole)) {
      return 'OL' as SupportedLocationType;
    }

    if (specialty === ('DIA' as ProviderRole)) {
      return 'IP' as SupportedLocationType;
    }

    return defaultValue;
  };

  /**
   * @description STEP 3: Get available dates of the current month
   */
  const fetchAvailableDatesByMonth = useCallback(
    async (month: string | null): Promise<DatesWithPrioritizedProviders | null> => {
      if (!month || !stepsRef.current || !selectedLanguage) {
        return null;
      }

      const currentStep = stepsRef.current.find((s) => s.active);

      if (!currentStep) {
        return null;
      }

      const clinicId = clinicState!.details.clinicId;

      const { allowedIntention, allowedSpecialty } = currentStep.config;

      const payload = {
        specialty: allowedSpecialty,
        month,
        appointmentIntention: allowedIntention,
        language: selectedLanguage,
        clinicIds: [clinicId],
        usePriorities: await FeatureManager.showPrioritizedAppointments(),
        ...(user?.systemSource && {
          systemSource: capitalizeToKebabCase(user?.systemSource) as LeadSystemSource,
        }),
        location: choseLocationBySpecialtyOrIntention(
          allowedSpecialty,
          allowedIntention,
          selectedLocation
        ),
        schedulingWorkflow: SchedulingWorkflow.SelfServeBooking,
      };

      const response = await withCache(
        `dates-${JSON.stringify(payload, null, 0)}`,
        () => scheduleClient.getPrioritizedMonthlyProviderAvailability(payload),
        CacheDuration.Short
      );

      if (!response) {
        return null;
      }

      const prioritizedDatesWithProviders = formatPrioritizedProvidersByDates(response.dates);

      if (!prioritizedDatesWithProviders) {
        return null;
      }

      return prioritizedDatesWithProviders;
    },
    [clinicState, selectedLanguage, selectedLocation, user?.systemSource]
  );

  /**
   * @description STEP 4: Get available time slots of a specific date (the first time takes the current day + days of space per rules)
   */
  const fetchTimeSlotsByDate = useCallback(
    async (
      currentDate: DateTime,
      availableProviders: AvailableProvider[]
    ): Promise<AvailableTimeSlotData[] | null> => {
      if (!stepsRef.current || !selectedLanguage) {
        return null;
      }

      const currentStep = stepsRef.current.find((s) => s.active);

      if (!currentStep) {
        return null;
      }

      setAvailableProviders(availableProviders);

      const { allowedIntention, allowedSpecialty } = currentStep.config;

      const payload = {
        clinicIds: [clinicState!.details.clinicId],
        specialty: allowedSpecialty,
        date: currentDate.toFormat('yyyy-MM-dd'),
        appointmentIntention: allowedIntention,
        language: selectedLanguage,
        usePriorities: await FeatureManager.showPrioritizedAppointments(),
        ...(user?.systemSource && {
          systemSource: capitalizeToKebabCase(user?.systemSource) as LeadSystemSource,
        }),
        location: choseLocationBySpecialtyOrIntention(
          allowedSpecialty,
          allowedIntention,
          selectedLocation
        ),
        schedulingWorkflow: SchedulingWorkflow.SelfServeBooking,
      };

      const availableAppointments = await withCache(
        `times-${JSON.stringify(payload, null, 0)}`,
        () => scheduleClient.getPrioritizedDailyProviderAvailability(payload),
        CacheDuration.VeryShort
      );

      if (!availableAppointments) {
        return null;
      }

      const prioritizedTimeSlots = cleanAndFormatSlots(
        availableAppointments,
        DateTime.local().zoneName
      );

      return prioritizedTimeSlots;
    },
    [selectedLanguage, selectedLocation, clinicState, user?.systemSource]
  );

  const getPreviousAppointmentByRulesUseCallback = useCallback(getPreviousAppointmentByRules, [
    selectedLanguage,
    stepsRef.current,
  ]);

  /**
   * @description STEP 2: Initialize the calendar to obtain dates and time slots
   */
  const resetCalendar = () => {
    if (
      !clinicState ||
      !stepsRef.current ||
      !scheduledAppointments ||
      !selectedLanguage ||
      isSubmitting
    ) {
      return <LoadingIndicator />;
    }

    if (
      FeatureManager.isLocationAvailableForClinic(
        clinicState!.details.clinicId,
        ProgramType.InClinic
      ) &&
      !selectedLocation
    ) {
      return <LoadingIndicator />;
    }

    const previousAppointmentByRules = getPreviousAppointmentByRulesUseCallback({
      scheduledAppointments,
      stepsRef,
      selectedLanguage,
    });

    return (
      <AppointmentsScheduler
        ref={appointmentsSchedulerRef}
        onNoAvailabilityFound={(value) => {
          if (!isKafriClinic(clinicState.details.clinicId) && value) {
            Toast.notification('info', 'There are not appointments available.', {
              draggable: false,
              closeOnClick: true,
              autoClose: false,
            });
          }
          setThereIsNoAvailability(value);
          setIsLanguageSelectorOpen(value);
        }}
        initialDaysToSkip={DEFAULT_DAYS_TO_SKIP_BETWEEN_APPOINTMENTS}
        previousAppointment={previousAppointmentByRules}
        onSelectHour={setSelectedTimeSlotKey}
        getCurrentAvailability={fetchTimeSlotsByDate}
        getAvailableDatesInMonth={fetchAvailableDatesByMonth}
      />
    );
  };

  const handleLanguageSelection = (pickedLanguage: AvailableLanguages | null) => {
    if (pickedLanguage) {
      setSelectedLanguage(pickedLanguage);
      setIsLanguageSelectorOpen(false);
    }
  };

  /**
   * @description
   * STEP 0: (Only for non-kafri clinics) Select a language
   * STEP 1: Set stepsRef.current by rules (enabled clinic + programType) with current status
   * STEP 2: Initialize the calendar to obtain dates and time slots
   * STEP 3: Get available dates of the current month
   * STEP 4: Get available time slots of a specific date (the first time takes the current day + days of space per rules)
   * STEP 5: Schedule with chosen date and time slot
   */

  useEffect(() => {
    if (clinicState) {
      evaluateCurrentState(ssbState, {
        context: {
          clinic: clinicState!,
          user,
          routePath,
          navigate,
          stepsRef,
          appointmentsSchedulerRef,
          selectedLanguage,
          scheduledAppointments,
          selectedTimeSlotKey,
          availableProviders,
        },
        setters: {
          setSelectedLanguage,
          setScheduledAppointments,
          setPullingAppointments,
          setIsEnabledBasedOnSpecialty,
          setSelectedLocation,
          setIsSubmitting,
          setAvailableProviders,
          setSelectedTimeSlotKey,
          dispatchOnboarding,
        },
        setState: dispatchSSB,
      });
    }
  }, [ssbState]);

  useEffect(() => {
    (async () => {
      if (
        FeatureManager.isLocationAvailableForClinic(
          clinicState!.details.clinicId,
          user!.programType
        ) &&
        stepsRef.current &&
        selectedLocation &&
        selectedLanguage
      ) {
        const previousAppointment = getPreviousAppointmentByRulesUseCallback({
          scheduledAppointments,
          stepsRef,
          selectedLanguage,
        });

        appointmentsSchedulerRef.current?.resetCalendar(previousAppointment ?? undefined);
      }

      const currentStep = stepsRef.current?.find((s) => s.active);

      if (currentStep && !selectedLocation) {
        configureLocationAndSelectorVisibility({
          context: { stepConfig: currentStep.config },
          setters: {
            setIsEnabledBasedOnSpecialty,
            setSelectedLocation,
          },
        });
      }
    })();
  }, [selectedLocation, stepsRef.current]);

  useEffect(() => {
    if (!selectedTimeSlotKey) {
      return;
    }

    if (DateTime.fromISO(selectedTimeSlotKey).diffNow('days').days > MIN_DAYS_TO_DISPLAY_ALERT) {
      setScheduleSuggestionIsOpen(true);
    }
  }, [selectedTimeSlotKey]);

  const renderLanguageSelector = (
    shouldDisplayOpened: boolean,
    showMessageForNoAvailability: boolean
  ) => {
    return (
      <LanguageSelector
        isOpen={shouldDisplayOpened}
        onSelect={handleLanguageSelection}
        noAvailableAppointments={showMessageForNoAvailability}
        value={selectedLanguage}
      />
    );
  };

  if (isKafriClinic(clinicState!.details.clinicId) && !selectedLanguage) {
    return renderLanguageSelector(true, thereIsNoAvailability);
  }

  if (!stepsRef.current) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <SSBTitle
        steps={stepsRef.current}
        programType={user!.programType}
        pullingAppointments={pullingAppointments}
      />
      <BasicLayout
        {...(availableProviders && {
          buttonProps: {
            text: 'Confirm appointment',
            disabled: !selectedTimeSlotKey,
            loading: isSubmitting,
            onClick: () =>
              dispatchSSB({ type: SET_SSB_INFO, payload: SSBFlowState.CreateAppointment }),
          },
        })}>
        {pullingAppointments ? (
          <Stack display='flex' alignItems={'center'} flexDirection={'column'}>
            <LoadingIndicator />
          </Stack>
        ) : (
          <>
            {isKafriClinic(clinicState!.details.clinicId) && selectedLanguage && (
              <>
                <ChangeLanguageLink
                  language={getLanguageName(selectedLanguage)}
                  onClick={() => {
                    setIsLanguageSelectorOpen(true);
                  }}
                />
                {renderLanguageSelector(isLanguageSelectorOpen, thereIsNoAvailability)}
              </>
            )}
            {FeatureManager.isLocationAvailableForClinic(
              clinicState!.details.clinicId,
              ProgramType.InClinic
            ) &&
              isEnabledBasedOnSpecialty && (
                <LocationSelector
                  onChange={setSelectedLocation}
                  selectedLocation={selectedLocation}
                  type={LocationSelectorType.Selector}
                  options={RADIO_LOCATION_SELECTOR_OPTIONS}
                />
              )}

            {FeatureManager.isLocationAvailableForClinic(
              clinicState!.details.clinicId,
              ProgramType.InClinic
            )
              ? selectedLocation && resetCalendar()
              : resetCalendar()}

            {scheduleSuggestionIsOpen && (
              <SchedulingSuggestion
                open={scheduleSuggestionIsOpen}
                onClose={setScheduleSuggestionIsOpen}
              />
            )}
          </>
        )}
      </BasicLayout>
    </>
  );
};

export default withProgress(SelfServeBooking, Page.SelfServeBooking);
