import {
  AppointmentBookingMethod,
  AvailableLanguages,
  AvailableTimeSlotData,
  DatesWithPrioritizedProviders,
  LeadOnboardingStage,
  LeadStatus,
  LeadSystemSource,
  MEMBER_SUCCESS_APPOINTMENT_TYPE_ID,
  MEMBER_SUCCESS_FOLLOW_UP_APPOINTMENT_TYPE_ID,
  MemberSuccessRole,
  MixpanelClient,
  MixpanelEvent,
  ProfileStatus,
  ProgramType,
  ProviderRole,
  SchedulingWorkflow,
  StatsigEventName,
  StatsigManager,
  Step,
  SupportedAppointmentIntention,
  SupportedLocationType,
  getMsAppointmentsFilteredByEligibilityMatch,
  getNextAvailableWeekdayForScheduling,
  isHPSMInsurance,
  onboardingClient,
  reportErrorToHoneybadger,
  scheduleClient,
  userClient,
} from '@enaratech/funnel-helper';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Agreements, { RefAgreements } from 'src/components/Common/Agreements/Agreements';
import LoadingIndicator from 'src/components/Common/LoadingIndicator/LoadingIndicator';
import { Page, withProgress } from 'src/components/Common/Progress/Progress';
import Toast from 'src/components/Common/Toast/Toast';
import BasicLayout from 'src/components/Layout/BasicLayout/BasicLayout';
import { useAuth } from 'src/contexts/auth';
import { SET_INITIAL_APPOINTMENT_ID } from 'src/contexts/auth/types';
import { useClinic } from 'src/contexts/clinic/index';
import { useExperiment } from 'src/contexts/experiments';
import { useOnboarding } from 'src/contexts/onboarding';
import { SET_BOOKING_METHOD } from 'src/contexts/onboarding/types';
import { useRoutePath } from 'src/hooks/useRoutePath';
import { CacheDuration, withCache } from 'src/lib/local-cache';
import { navigateToPage } from 'src/pages/routes';
import { capitalizeToKebabCase, randomElementFromArray } from 'src/utils/array';
import AppointmentsScheduler, {
  AppointmentsSchedulerRef,
} from '../Scheduler/AppointmentsScheduler/AppointmentsScheduler';
import LocationSelector, {
  LocationSelectorType,
  RADIO_LOCATION_SELECTOR_OPTIONS,
} from '../SelfServeBooking/LocationSelector';
import {
  cleanAndFormatSlots,
  formatPrioritizedProvidersByDates,
  getDataFromTimeSlotKey,
} from '../SelfServeBooking/rules/appointments';
import { AvailableProvider } from '../SelfServeBooking/rules/ssbFlow/types';
import './scss/memberSuccess.scss';

// TODO: Move variable to helper if experiment works
const MEMBER_SUCCESS_IN_CLINIC_APPOINTMENT_TYPE_ID =
  process.env.REACT_APP_MEMBER_SUCCESS_IN_CLINIC_APPOINTMENT_TYPE_ID;

const MemberSuccessBooking: FC = () => {
  const [appointmentDateTime, setAppointmentDateTime] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [agreementsLoaded, setAgreementsLoaded] = useState<boolean>(false);
  const [allAgreementsSelected, setAllAgreementsSelected] = useState<boolean>(false);
  const [availableProviders, setAvailableProviders] = useState<AvailableProvider[] | null>(null);
  const [currentStep, setCurrentStep] = useState<Step | null>(null);
  const [locationForMXOnboarding, setLocationForMXOnboarding] = useState<
    SupportedLocationType | undefined
  >();
  const [calendarId] = useState<string>('');

  const [thereIsNoAvailability, setThereIsNoAvailability] = useState<boolean>(false);

  const appointmentsSchedulerRef = useRef<AppointmentsSchedulerRef | null>(null);
  const agreementsRef = useRef<RefAgreements>(null);
  const stepsRef = useRef<Step[] | null>(null);

  const navigate = useNavigate();

  const { clinicState } = useClinic();
  const {
    onboardingState: { eligibility },
    dispatchOnboarding,
  } = useOnboarding();

  const {
    authState: { user },
    dispatchAuth,
  } = useAuth();

  const routePath = useRoutePath();

  // Experiment facing changes
  const {
    experimentState: { isInitialized },
  } = useExperiment();

  const isArrivingFromAdwords = useMemo(() => {
    if (!isInitialized) {
      return false;
    }

    return StatsigManager.isArrivingFromAdwords();
  }, [isInitialized]);

  const createAppointmentWithMemberSuccessAgent = useCallback(async () => {
    setIsSubmitting(true);

    if (!availableProviders || !(availableProviders.length > 0) || !appointmentDateTime) {
      const error = {
        message: 'We could not create the appointment',
        availableProviders,
        appointmentDateTime,
      };
      Toast.notification('error', error.message);
      return reportErrorToHoneybadger({
        error: JSON.stringify(error),
      });
    }

    const { dateTime, acuityCalendarIds } = getDataFromTimeSlotKey(appointmentDateTime);

    const randomAcuityCalendarId = randomElementFromArray<number>(acuityCalendarIds);

    const appointmentCreated = await scheduleClient.createAppointmentWithMemberSuccess({
      calendarId: `${randomAcuityCalendarId}`,
      dateTime: dateTime!,
      appointmentTypeId: isArrivingFromAdwords
        ? Number(MEMBER_SUCCESS_FOLLOW_UP_APPOINTMENT_TYPE_ID)
        : locationForMXOnboarding === 'IP'
        ? Number(MEMBER_SUCCESS_IN_CLINIC_APPOINTMENT_TYPE_ID)
        : Number(MEMBER_SUCCESS_APPOINTMENT_TYPE_ID),
    });

    if (
      appointmentCreated &&
      (await userClient.updateUser({
        initialAppointmentId: appointmentCreated.id,
        msAppointmentTime: appointmentDateTime!,
        lead: false,
      }))
    ) {
      userClient.activateUser();
      userClient.setProfileStatus(ProfileStatus.Onboarding);

      onboardingClient.updateMemberStatus({
        onboardingStage: LeadOnboardingStage.MemberSuccessCallScheduling,
        bookingMethod: AppointmentBookingMethod.MSCall,
        crm: { leadStatus: LeadStatus.Scheduled },
      });

      dispatchOnboarding({ type: SET_BOOKING_METHOD, payload: AppointmentBookingMethod.MSCall });
      dispatchAuth({ type: SET_INITIAL_APPOINTMENT_ID, payload: appointmentCreated.id });

      MixpanelClient.trackEvent({
        event: MixpanelEvent.InputData,
        properties: {
          field: 'Appointment created',
          value: 'Member Success Manager',
          source: routePath,
        },
      });

      if (!isHPSMInsurance(user!.insuranceCompany)) {
        MixpanelClient.trackEvent({
          event: MixpanelEvent.InputData,
          properties: {
            field: 'MagicLink Prospect',
            value: !eligibility.membershipType,
            source: routePath,
          },
        });
      }

      if (agreementsRef.current) {
        agreementsRef.current.signAgreements();
      }

      MixpanelClient.setMetadata({ msCall: true });

      if (isArrivingFromAdwords) {
        // Log event to Statsig when a user schedules a call with Question Talk
        // if they are coming from an adwords campaign
        StatsigManager.logEvent({
          eventName: StatsigEventName.QuestionCallScheduled,
          metadata: {
            memberId: `${user!.id}`,
            memberUuid: user!.uuid,
          },
        });
        navigateToPage({ targetPage: '/insurance', navigate });
      } else {
        navigateToPage({ targetPage: '/appointment/ms-booking/result', navigate });
      }
    } else {
      Toast.notification('error', 'Appointment was not created');
    }

    setIsSubmitting(false);
  }, [
    calendarId,
    appointmentDateTime,
    isArrivingFromAdwords,
    isInitialized,
    currentStep,
    locationForMXOnboarding,
  ]);

  const handleAgreementsLoaded = useCallback(() => {
    setAgreementsLoaded(true);
  }, []);

  const handleAgreementSelection = useCallback((all: boolean) => {
    setAllAgreementsSelected(all);
  }, []);

  const fetchTimeSlotsByDate = useCallback(
    async (
      currentDate: DateTime,
      availableProviders: AvailableProvider[]
    ): Promise<AvailableTimeSlotData[] | null> => {
      setAvailableProviders(availableProviders);

      const appointmentIntention = isArrivingFromAdwords
        ? SupportedAppointmentIntention.FollowUp
        : SupportedAppointmentIntention.Initial;

      const payload = {
        date: currentDate.toFormat('yyyy-MM-dd'),
        appointmentIntention,
        language: AvailableLanguages.English,
        specialty: MemberSuccessRole.MemberSuccess,
        clinicIds: [clinicState!.details.clinicId],
        usePriorities: true,
        ...(user?.systemSource && {
          systemSource: capitalizeToKebabCase(user?.systemSource) as LeadSystemSource,
        }),
        location: locationForMXOnboarding,
        schedulingWorkflow: SchedulingWorkflow.OnboardingCall,
      };

      const availableAppointments = await withCache(
        `times-${JSON.stringify(payload, null, 0)}`,
        () => scheduleClient.getPrioritizedDailyProviderAvailability(payload),
        CacheDuration.VeryShort
      );

      if (!availableAppointments) {
        return [];
      }

      const prioritizedTimeSlots = cleanAndFormatSlots(
        availableAppointments,
        DateTime.local().zoneName
      );

      return getMsAppointmentsFilteredByEligibilityMatch(
        prioritizedTimeSlots,
        DateTime.local().zoneName,
        !!eligibility.membershipType
      );
    },
    [
      clinicState,
      locationForMXOnboarding,
      isArrivingFromAdwords,
      eligibility.membershipType,
      user?.systemSource,
    ]
  );

  const fetchAvailableDatesByMonth = useCallback(
    async (month: string | null): Promise<DatesWithPrioritizedProviders | null> => {
      if (!month) {
        return null;
      }

      const appointmentIntention = isArrivingFromAdwords
        ? SupportedAppointmentIntention.FollowUp
        : SupportedAppointmentIntention.Initial;

      const payload = {
        specialty: MemberSuccessRole.MemberSuccess as unknown as ProviderRole,
        month,
        appointmentIntention,
        language: AvailableLanguages.English,
        clinicIds: [clinicState!.details.clinicId],
        usePriorities: true,
        ...(user?.systemSource && {
          systemSource: capitalizeToKebabCase(user?.systemSource) as LeadSystemSource,
        }),
        location: locationForMXOnboarding,
        schedulingWorkflow: SchedulingWorkflow.OnboardingCall,
      };

      const response = await withCache(
        `days-${JSON.stringify(payload, null, 0)}`,
        () => scheduleClient.getPrioritizedMonthlyProviderAvailability(payload),
        CacheDuration.Short
      );

      if (!response) {
        return null;
      }

      const prioritizedDatesWithProviders = formatPrioritizedProvidersByDates(response.dates);

      if (!prioritizedDatesWithProviders) {
        return null;
      }

      return prioritizedDatesWithProviders;
    },
    [locationForMXOnboarding, isArrivingFromAdwords, clinicState, user?.systemSource]
  );

  const getPageTitle = (): string => {
    const { firstName } = user ?? { firstName: 'Unknown' };

    if (eligibility.membershipType) {
      return `Fantastic, ${firstName}!`;
    }

    const experimentMXOnboardingMessage = `${
      firstName?.[0].toUpperCase() + firstName?.slice(1)
    }, we need to further check your eligibility`;

    return currentStep && currentStep?.config.allowedLocations.length > 1
      ? experimentMXOnboardingMessage
      : `Thank you for providing your details, ${firstName}!`;
  };

  const subtitleWrapper = (children: ReactNode) => (
    <div>
      <Typography variant={'h4'}>{children}</Typography>
    </div>
  );

  const getPageSubtitle = useMemo((): ReactNode => {
    const userChooseOnboardingMessage = `Let’s set up an ${
      locationForMXOnboarding === 'IP' ? 'on-site' : 'online'
    } meeting to continue with the Enara Program Onboarding.`;

    return subtitleWrapper(userChooseOnboardingMessage);
  }, [locationForMXOnboarding]);

  const memorizedCalendar = useMemo(
    () => (
      <AppointmentsScheduler
        ref={appointmentsSchedulerRef}
        initialDaysToSkip={getNextAvailableWeekdayForScheduling(!!eligibility.membershipType)}
        onNoAvailabilityFound={(noAvailabilityFound) => {
          if (noAvailabilityFound) {
            Toast.notification('info', 'There are not appointments available.', {
              draggable: false,
              closeOnClick: true,
              autoClose: false,
            });
          }
          setThereIsNoAvailability(noAvailabilityFound);
        }}
        onSelectHour={(selectedAvailability: string | null) => {
          setAppointmentDateTime(selectedAvailability);
        }}
        getAvailableDatesInMonth={fetchAvailableDatesByMonth}
        getCurrentAvailability={fetchTimeSlotsByDate}
      />
    ),
    [
      fetchTimeSlotsByDate,
      fetchAvailableDatesByMonth,
      thereIsNoAvailability,
      locationForMXOnboarding,
    ]
  );

  useEffect(() => {
    (async () => {
      if (!currentStep) {
        const fetchedSteps = await scheduleClient.getStepsForMember({
          clinicId: clinicState!.details.clinicId,
          memberId: user!.id,
          programType: user!.programType as ProgramType.InClinic | ProgramType.Remote,
          systemSource: capitalizeToKebabCase(user!.systemSource) as LeadSystemSource,
          schedulingWorkflow: SchedulingWorkflow.OnboardingCall,
        });

        stepsRef.current = fetchedSteps;

        // MX only has one step
        const currentStep = stepsRef.current?.[0];

        if (!fetchedSteps || !currentStep) {
          return Toast.notification(
            'error',
            'There was an error obtaining the information. Please reload the page or wait for a support member to contact you, we are looking for the solution.'
          );
        }

        setCurrentStep(currentStep);
        setLocationForMXOnboarding(currentStep.config.allowedLocations[0]);
      }
    })();

    appointmentsSchedulerRef.current?.resetCalendar();
  }, []);

  useEffect(() => {
    appointmentsSchedulerRef.current?.resetCalendar();
  }, [locationForMXOnboarding]);

  if (!currentStep || !locationForMXOnboarding) {
    return <LoadingIndicator />;
  }

  return (
    <BasicLayout
      title={getPageTitle()}
      subtitle={
        <Box>
          <Typography variant={'h4'} sx={{ marginBottom: '1em' }}>
            {getPageSubtitle}
          </Typography>
          {currentStep && currentStep?.config.allowedLocations.length > 1 && (
            <LocationSelector
              onChange={setLocationForMXOnboarding}
              selectedLocation={locationForMXOnboarding}
              type={LocationSelectorType.Radio}
              options={RADIO_LOCATION_SELECTOR_OPTIONS}
            />
          )}
        </Box>
      }
      buttonProps={{
        text: 'Confirm Appointment',
        disabled:
          !appointmentDateTime ||
          (isHPSMInsurance(user!.insuranceCompany) &&
            (!agreementsLoaded || !allAgreementsSelected)),
        loading: isSubmitting,
        onClick: createAppointmentWithMemberSuccessAgent,
      }}>
      {memorizedCalendar}

      {isHPSMInsurance(user!.insuranceCompany) && (
        <Agreements
          ref={agreementsRef}
          agreementsMode={'needs-auth'}
          className='do-not-overlap'
          onLoaded={handleAgreementsLoaded}
          onSelect={handleAgreementSelection}
        />
      )}
      {eligibility.membershipType && (
        <Typography variant='h6' align='center' marginTop='40px' fontWeight='bold'>
          Our Member Success Specialists are available Monday through Friday, 8am - 5pm PT
        </Typography>
      )}
    </BasicLayout>
  );
};

export default withProgress(MemberSuccessBooking, Page.MemberSuccessBooking);
