import { SSBFlowState } from 'src/contexts/ssb/types';
import { allScheduledAction } from './actions/allScheduledAction';
import { createAppointmentAction } from './actions/createAppointmentAction';
import { fetchStatusAction } from './actions/fetchStatusAction';
import { goToNextStepAction } from './actions/goToNextStepAction';
import { initializeSchedulingAction } from './actions/initializeSchedulingAction';

export const evaluateCurrentState = (
  state: SSBFlowState | null,
  data: { context: any; setters: any; setState: any }
) => {
  switch (state) {
    case SSBFlowState.InitialScheduling:
      initializeSchedulingAction(data);
      break;

    case SSBFlowState.GoToNextStep:
      goToNextStepAction(data);
      break;

    case SSBFlowState.PendingScheduling:
      // !NOTE: Do nothing, waiting for scheduling
      break;

    case SSBFlowState.FetchSchedulingStatus:
      fetchStatusAction(data);
      break;

    case SSBFlowState.CreateAppointment:
      createAppointmentAction(data);
      break;

    case SSBFlowState.AllScheduled:
      allScheduledAction(data);
      break;

    default:
      initializeSchedulingAction(data);
      break;
  }
};
