import { Dispatch } from 'react';

export enum SSBFlowState {
  InitialScheduling = 'initial-scheduling',
  FetchSchedulingStatus = 'fetching-scheduling-status',
  CreateAppointment = 'create-appointment',
  AllScheduled = 'all-scheduled',
  PendingScheduling = 'pending-scheduling',
  GoToNextStep = 'go-to-next-step',
}

export type SSBState = SSBFlowState | null;

export type SSBContextValue = {
  ssbState: SSBState;
  dispatchSSB: Dispatch<SSBActionTypes>;
};

export const SET_SSB_INFO = '@enara/types/set-ssb-info';

export type SetSSBInfoType = {
  type: typeof SET_SSB_INFO;
  payload: SSBState;
};

export type SSBActionTypes = SetSSBInfoType;
